.headers[data-v-4743ae69] {
  background: #ffffff;
  padding: 14px 12px;
}
.headers[data-v-4743ae69] .el-button {
  height: 28px;
}
.el-button + .el-button[data-v-4743ae69] {
  margin-left: 5px !important;
}
[data-v-4743ae69] .bjs-powered-by {
  display: none;
}
.courseCoverImg[data-v-4743ae69] {
  width: 80px;
  padding: 5px;
}