.type-list[data-v-2102682e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.type-list .type-list-item[data-v-2102682e] {
  width: 110px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #fff;
}
.type-list .type-list-item[data-v-2102682e]:last-child, .type-list .type-list-item[data-v-2102682e]:nth-last-child(2) {
  margin-bottom: 0;
}
.type-list .type-list-item.active[data-v-2102682e] {
  border: 1px solid #999;
}