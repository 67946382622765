.margin20[data-v-a4017f28] {
  margin-bottom: 20px;
}
.label-width[data-v-a4017f28] {
  width: 100px;
  line-height: 32px;
  text-align: right;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.label-width-main[data-v-a4017f28] {
  width: 87px;
  line-height: 32px;
  text-align: right;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.treeselect-main[data-v-a4017f28] {
  width: 100%;
}