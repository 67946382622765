.leftQuarter[data-v-2b1881df] {
  width: 200px;
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #ffffff;
  border-right: 1px solid #cccccc;
}
.riskClass[data-v-2b1881df] .common_tree_box .common_tree_handle {
  width: 240px;
}
.quarterBox[data-v-2b1881df] {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px solid #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 8px;
  position: relative;
}
.quarterBox .deleteIcon[data-v-2b1881df] {
  font-size: 16px;
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
  color: #f56c6c;
}
.quarterActiveClass[data-v-2b1881df] {
  border: 1px solid #2979ff;
  background-color: #b3d8ff;
}
.quarterAddBox[data-v-2b1881df] {
  width: calc(100% - 14px);
  padding: 0 6px;
  border: 1px dashed #d4d4d4;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606060;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
}
.introduceClass[data-v-2b1881df] .common_tree_handle .el-tree {
  height: calc(100vh - 421px) !important;
}
[data-v-2b1881df] .introduceTable .avue-crud .el-table {
  height: calc(100vh - 385px) !important;
  max-height: calc(100vh - 385px) !important;
}
.main-content[data-v-2b1881df] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.main-content .top[data-v-2b1881df] {
  width: 100%;
  height: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.main-content .top .canvas-box[data-v-2b1881df] {
  border-radius: 4px;
  width: 50%;
  height: 100%;
  background: #fff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 10px;
}
.main-content .top .canvas-box .title-box[data-v-2b1881df] {
  width: calc(100% - 20px);
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.main-content .bottom[data-v-2b1881df] {
  border-radius: 4px;
  margin-top: 10px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: calc(50% - 10px);
  overflow: scroll;
}
.main-content .bottom .el-container[data-v-2b1881df] {
  height: 100%;
}
.main-content .bottom .el-container .el-main[data-v-2b1881df] {
  height: 100%;
  overflow: hidden;
}
.el-main[data-v-2b1881df] {
  overflow: hidden;
}
.tagBox[data-v-2b1881df] {
  position: absolute;
  left: 100px;
  top: 9px;
  z-index: 99;
}