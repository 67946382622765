.casually[data-v-1e0770c4] {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}
.casually .flex-c[data-v-1e0770c4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.casually .avue-crud .el-table[data-v-1e0770c4] {
  height: calc(100vh - 334px) !important;
  max-height: calc(100vh - 334px) !important;
}
.casually .status-box[data-v-1e0770c4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3px;
}
.casually .status[data-v-1e0770c4] {
  border-color: #e9e9eb;
  border-width: 1px;
  border-style: solid;
  color: #909399;
  background: #f4f4f5;
  border-radius: 3px;
  font-size: 12px;
  padding: 0px;
  width: 67px;
  height: 26px;
  line-height: 26px;
  display: inline-block;
  text-align: center;
}
.casually .unaccept[data-v-1e0770c4] {
  border-color: rgb(226, 152, 54);
  border-width: 1px;
  border-style: solid;
  color: rgb(226, 152, 54);
  background: rgb(253, 245, 233);
}
.casually .unrectify[data-v-1e0770c4] {
  border-color: rgb(86, 92, 199);
  border-width: 1px;
  border-style: solid;
  color: rgb(86, 92, 199);
  background: rgb(232, 235, 248);
}
.casually .finished[data-v-1e0770c4] {
  border-color: rgb(122, 199, 86);
  border-width: 1px;
  border-style: solid;
  color: rgb(122, 199, 86);
  background: rgb(238, 248, 232);
}
.casually .canceled[data-v-1e0770c4] {
  border-color: rgb(242, 97, 97);
  border-width: 1px;
  border-style: solid;
  color: rgb(242, 97, 97);
  background: rgb(254, 238, 238);
}
.casually .unassess[data-v-1e0770c4] {
  border-color: rgb(56, 148, 255);
  border-width: 1px;
  border-style: solid;
  color: rgb(56, 148, 255);
  background: rgb(233, 243, 255);
}
[data-v-1e0770c4] .el-tabs__header {
  margin: 0;
  background: #ffffff;
}
[data-v-1e0770c4] .el-tabs__content {
  padding: 0 !important;
}
[data-v-1e0770c4] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}