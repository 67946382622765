.board[data-v-447cacb4] {
  width: 100%;
}
.board .chart[data-v-447cacb4] {
  width: 100%;
  height: 100%;
}
.board .block-div[data-v-447cacb4] {
  height: 300px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}
.board .flex-cloum-div[data-v-447cacb4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.board .flex-cloum[data-v-447cacb4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.board .flex-div[data-v-447cacb4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.board .flex[data-v-447cacb4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.board .name[data-v-447cacb4] {
  margin-top: 20px;
  color: #15161a;
  font-size: 18px;
}
.board .title[data-v-447cacb4] {
  padding: 20px 0 10px 20px;
  color: #15161a;
  font-size: 20px;
  font-weight: bold;
}
.board .num[data-v-447cacb4] {
  color: #15161a;
  font-size: 42px;
  font-weight: bold;
}
.board .unit[data-v-447cacb4] {
  color: #484a4d;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}
.board .content[data-v-447cacb4] {
  height: calc(100% - 60px);
}
.board .w-50[data-v-447cacb4] {
  width: calc(50% - 10px);
}
.board .w-100[data-v-447cacb4] {
  width: 100%;
}
.board .w-25[data-v-447cacb4] {
  width: 25%;
}
.board .m-t-20[data-v-447cacb4] {
  margin-top: 20px;
}
.board .h-50[data-v-447cacb4] {
  height: 50%;
}
.board .h-100[data-v-447cacb4] {
  height: 100%;
}
.board .m-r-20[data-v-447cacb4] {
  margin-right: 20px;
}
.board .m-l-20[data-v-447cacb4] {
  margin-left: 20px;
}
.board .h-200[data-v-447cacb4] {
  height: 200px;
}