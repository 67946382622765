[data-v-1d24fedd] .common_tree_handle {
  height: 100% !important;
}
.areaCheckList[data-v-1d24fedd] {
  height: 100%;
}
[data-v-1d24fedd] .el-container {
  height: 100%;
}
[data-v-1d24fedd] .el-container .el-main {
  overflow: hidden;
}
[data-v-1d24fedd] .tabGridLayOut {
  height: calc(100% - 100px) !important;
}
[data-v-1d24fedd] .avue-crud {
  height: 100% !important;
}
[data-v-1d24fedd] .avue-crud .el-card {
  height: 100% !important;
  overflow: hidden;
}
[data-v-1d24fedd] .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-1d24fedd] .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-1d24fedd] .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
  max-height: 100% !important;
}
[data-v-1d24fedd] .areaCheckListTree .el-tree {
  height: calc(100% - 120px) !important;
}