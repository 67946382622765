input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
.editor-tabs {
  background: #121315;
}
.editor-tabs .el-tabs__header {
  margin: 0;
  border-bottom-color: #121315;
}
.editor-tabs .el-tabs__header .el-tabs__nav {
  border-color: #121315;
}
.editor-tabs .el-tabs__item {
  height: 32px;
  line-height: 32px;
  color: #888a8e;
  border-left: 1px solid #121315 !important;
  background: #363636;
  margin-right: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.editor-tabs .el-tabs__item.is-active {
  background: #1e1e1e;
  border-bottom-color: #1e1e1e !important;
  color: #fff;
}
.editor-tabs .el-icon-edit {
  color: #f1fa8c;
}
.editor-tabs .el-icon-document {
  color: #a95812;
}
.right-scrollbar .el-scrollbar__view {
  padding: 10px;
}
.right-scrollbar .el-scrollbar__view .el-input-number {
  width: 100%;
}
.el-scrollbar__wrap {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__header {
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__item {
  width: 33.33%;
  text-align: center;
}
.center-tabs .el-tabs__nav {
  width: 100%;
}
.reg-item {
  padding: 12px 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
}
.reg-item .close-btn {
  position: absolute;
  right: -6px;
  top: -6px;
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  font-size: 12px;
}
.reg-item .close-btn:hover {
  background: rgba(210, 23, 23, 0.5);
}
.reg-item + .reg-item {
  margin-top: 18px;
}
.action-bar .el-button + .el-button {
  margin-left: 15px;
}
.action-bar i {
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.main .el-scrollbar__view {
  overflow-x: hidden;
}
.main .el-rate {
  display: inline-block;
  vertical-align: text-top;
}
.main .el-upload__tip {
  line-height: 1.2;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
}
.components-list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}
.components-list .components-part {
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px 0;
  margin-bottom: 10px;
}
.components-list .components-part:last-child {
  margin-bottom: 0;
}
.components-list .components-draggable {
  margin-right: -10px;
}
.components-list .components-item {
  display: inline-block;
  width: 110px;
  margin: 0 10px 10px 0;
  -webkit-transition: -webkit-transform 0ms !important;
  transition: -webkit-transform 0ms !important;
  transition: transform 0ms !important;
  transition: transform 0ms, -webkit-transform 0ms !important;
}
.components-list .components-item.disabled .components-body {
  background: #E4E7ED;
  cursor: not-allowed;
  border: none !important;
  color: #999 !important;
}
.components-list .components-item.disabled .components-body .icon-ym {
  color: #999 !important;
}
.components-list .components-item.disabled .components-body:hover {
  border: none !important;
  color: #999 !important;
}
.components-list .components-item.disabled .components-body:hover .icon-ym {
  color: #999 !important;
}
.components-title {
  font-size: 14px;
  color: #043254;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}
.components-body {
  padding-left: 8px;
  background: #F3F9FF;
  font-size: 12px;
  height: 36px;
  cursor: move;
  border: 1px dashed #F3F9FF;
  border-radius: 3px;
  color: #043254;
  line-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.components-body i {
  color: #043254;
  line-height: 16px;
  height: 16px;
  margin-right: 4px;
}
.components-body:hover {
  border: 1px dashed #409EFF;
  color: #409EFF;
}
.components-body:hover i {
  color: #409EFF;
}
.left-board {
  width: 250px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
}
.left-scrollbar {
  height: 100%;
  overflow: hidden;
}
.left-scrollbar .el-scrollbar__wrap {
  overflow-x: auto;
}
.center-scrollbar {
  height: calc(100% - 42px);
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.center-scrollbar .el-scrollbar__wrap {
  overflow-x: auto;
}
.center-scrollbar .el-scrollbar__view {
  overflow-x: hidden;
}
.center-board {
  height: 100%;
  width: auto;
  margin: 0 350px 0 260px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.center-board .snows-editor-quill .ql-editor {
  min-height: 100px !important;
}
.center-board .table-tip {
  width: 100%;
  color: #999;
  text-align: center;
  position: absolute;
  top: 60px;
  font-size: 14px;
}
.center-board .table-tip.tab-tip {
  top: 45px;
}
.center-board .table-tip.card-tip {
  top: 30px;
}
.center-board #ipad {
  height: calc(100% - 42px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 711px;
}
.center-board #ipad .outeripad {
  background: url(../../static/img/iphoneBg.86faf130.png);
  width: 389px;
  height: 711px;
  padding: 65px 40px;
}
.center-board #ipad .outeripad .ipadHead {
  background: #f7f8f9;
  text-align: center;
}
.center-board #ipad .outeripad .ipadHead .ipadHead-img {
  margin: 0 auto;
  height: 20px;
}
.center-board #ipad .outeripad .ipadbody {
  height: 100%;
}
.center-board #ipad .outeripad .ipadbody .center-board-row > .el-form {
  height: 550px;
}
.center-board #ipad .outeripad .ipadbody .center-scrollbar {
  height: 100%;
  overflow: hidden;
}
.empty-info {
  position: absolute;
  top: 20%;
  left: calc(50% - 250px);
}
.empty-info.app-empty-info {
  top: calc(50% - 150px);
  left: calc(50% - 150px);
}
.empty-info.app-empty-info .empty-img {
  width: 300px;
  height: 300px;
}
.empty-info .empty-img {
  width: 500px;
  height: 500px;
}
.action-bar {
  position: relative;
  height: 42px;
  text-align: center;
  padding: 0 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.action-bar .delete-btn {
  color: #F56C6C !important;
}
.action-bar .unActive-btn {
  color: #606266 !important;
}
.action-bar .unActive-btn:hover {
  color: #1890ff !important;
}
.action-bar .action-bar-right {
  position: absolute;
  right: 15px;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 42px;
}
.logo {
  position: absolute;
  left: 12px;
  top: 6px;
  line-height: 30px;
  color: #00afff;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;
}
.logo > img {
  width: 30px;
  height: 30px;
  vertical-align: top;
}
.logo .github {
  display: inline-block;
  vertical-align: sub;
  margin-left: 15px;
}
.logo .github > img {
  height: 22px;
}
.center-board-row {
  padding: 12px 12px 15px 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
}
.center-board-row > .el-form {
  height: calc(100vh - 150px);
}
.center-board-row .el-tabs__header {
  margin: 0;
}
.drawing-board {
  height: 100%;
  position: relative;
}
.drawing-board .el-select {
  width: 100%;
}
.drawing-board .components-body {
  padding: 0;
  margin: 0;
  font-size: 0;
}
.drawing-board .sortable-ghost {
  position: relative;
  display: block;
  overflow: hidden;
}
.drawing-board .sortable-ghost i {
  display: none;
}
.drawing-board .sortable-ghost .el-form-item i {
  display: inline;
}
.drawing-board .sortable-ghost::before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 3px;
  background: #409EFF;
  z-index: 2;
}
.drawing-board .components-item.sortable-ghost {
  width: 100%;
  height: 60px;
  background-color: #F3F9FF;
}
.drawing-board .active-from-item > .el-form-item {
  background: #F3F9FF;
  border: 1px solid #409EFF;
}
.drawing-board .active-from-item > .drawing-item-copy, .drawing-board .active-from-item > .drawing-item-delete {
  display: block;
}
.drawing-board .active-from-item > .component-name {
  color: #409EFF;
}
.drawing-board .el-form-item {
  margin-bottom: 10px !important;
}
.drawing-item {
  position: relative;
  cursor: move;
}
.drawing-item.unfocus-bordered:not(.active-from-item) > div:first-child {
  border: 1px dashed #ccc;
}
.drawing-item .el-form-item {
  border: 1px dashed #e2e0e0;
  padding: 10px;
}
.drawing-row-item {
  position: relative;
  cursor: move;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px dashed #ccc;
  padding: 10px 2px;
  margin-bottom: 10px;
}
.drawing-row-item .drawing-item-copy {
  right: 48px !important;
}
.drawing-row-item .drawing-item-delete {
  right: 16px !important;
}
.drawing-row-item .drawing-row-item {
  margin-bottom: 2px;
}
.drawing-row-item.drawing-row-item-table {
  padding-top: 15px;
}
.drawing-row-item.drawing-row-item-table .el-col {
  margin-top: 15px;
}
.drawing-row-item .el-form-item {
  margin-bottom: 0;
}
.drawing-row-item .drag-wrapper {
  min-height: 80px;
}
.drawing-row-item.active-from-item {
  border-color: #409EFF;
}
.drawing-row-item .component-name {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  color: #bbb;
  display: inline-block;
  padding: 5px 6px 0;
}
.drawing-item:hover > .el-form-item,
.drawing-row-item:hover > .el-form-item {
  background: #F3F9FF;
}
.drawing-item:hover > .drawing-item-copy, .drawing-item:hover > .drawing-item-delete,
.drawing-row-item:hover > .drawing-item-copy,
.drawing-row-item:hover > .drawing-item-delete {
  display: block;
}
.drawing-item > .drawing-item-copy, .drawing-item > .drawing-item-delete,
.drawing-row-item > .drawing-item-copy,
.drawing-row-item > .drawing-item-delete {
  display: none;
  position: absolute;
  top: -10px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid;
  cursor: pointer;
  z-index: 1;
}
.drawing-item > .drawing-item-copy,
.drawing-row-item > .drawing-item-copy {
  right: 56px;
  border-color: #409EFF;
  color: #409EFF;
  background: #fff;
}
.drawing-item > .drawing-item-copy:hover,
.drawing-row-item > .drawing-item-copy:hover {
  background: #409EFF;
  color: #fff;
}
.drawing-item > .drawing-item-delete,
.drawing-row-item > .drawing-item-delete {
  right: 24px;
  border-color: #F56C6C;
  color: #F56C6C;
  background: #fff;
}
.drawing-item > .drawing-item-delete:hover,
.drawing-row-item > .drawing-item-delete:hover {
  background: #F56C6C;
  color: #fff;
}
.drawing-row-item .el-card__body > .el-col {
  margin-top: 0 !important;
  margin-bottom: 10px;
}
.table-wrapper-web {
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.table-wrapper-web > .el-col {
  width: 200px !important;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.table-wrapper-web > .el-col .el-form-item {
  margin-bottom: 0 !important;
}