.headers[data-v-b8e2c676] {
  background: #ffffff;
  padding: 14px 12px;
}
.headers[data-v-b8e2c676] .el-button {
  height: 28px;
}
.el-button + .el-button[data-v-b8e2c676] {
  margin-left: 5px !important;
}
[data-v-b8e2c676] .bjs-powered-by {
  display: none;
}