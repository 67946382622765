.daily[data-v-54e734ee] {
  width: 100%;
}
.clacWidth[data-v-54e734ee] {
  width: calc(100% - 280px);
}
[data-v-54e734ee] .el-dialog__body {
  padding: 20px;
}
.kicket-content[data-v-54e734ee] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.kicket-content .kicket[data-v-54e734ee] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border: 1px solid #ededed;
  width: 250px;
  height: 90px;
  border-radius: 8px;
  margin: 10px 20px;
}
.kicket-content .kicket .img-item[data-v-54e734ee] {
  width: 40px;
  height: 40px;
}
.kicket-content .kicket .text-item[data-v-54e734ee] {
  color: #333333;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}