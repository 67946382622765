.card-tabs-style[data-v-359ee3a3] {
  height: calc(100% - 60px);
}
[data-v-359ee3a3] .el-tabs__content, .el-tab-pane[data-v-359ee3a3] {
  height: 100%;
}
.content-formFillingPlan[data-v-359ee3a3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
  height: 100%;
}
.content-formFillingPlan .left-content[data-v-359ee3a3] {
  height: 100%;
  width: 20%;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.content-formFillingPlan .right-content[data-v-359ee3a3] {
  height: 100%;
  width: calc(80% - 10px);
  margin-left: 10px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.safe-intelligent[data-v-359ee3a3] .el-card__body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100% !important;
}