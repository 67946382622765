.eventManagement {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}
.eventManagement .flex-c {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}