[data-v-219aac6e] .el-select,[data-v-219aac6e] .el-autocomplete,[data-v-219aac6e] .el-date-editor {
  width: 100%;
}
[data-v-219aac6e] .el-tabs__header {
  margin-bottom: 0;
}
.box[data-v-219aac6e] {
  position: relative;
}
.add-btn[data-v-219aac6e] {
  position: absolute;
  right: 10px;
  top: 2px;
  z-index: 100;
}
[data-v-219aac6e] .el-tabs .el-table .cell {
  padding: 0 5px;
}
[data-v-219aac6e] .el-tabs .el-table .el-input-number {
  width: 100%;
}
[data-v-219aac6e] .el-tabs .el-table .el-input-number .el-input-number__decrease,[data-v-219aac6e] .el-tabs .el-table .el-input-number .el-input-number__increase {
  display: none;
}
[data-v-219aac6e] .el-tabs .el-table .el-input-number .el-input__inner {
  padding: 0 15px;
  text-align: left;
}
.order-form[data-v-219aac6e] {
  height: 100%;
  overflow: hidden;
}
.main[data-v-219aac6e] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
  overflow-x: hidden;
}