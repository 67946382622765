.affix {
  position: relative;
  background-color: #fff;
}
.affix-affix {
  display: inline-block;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  background: rgba(0, 153, 229, 0.9);
}
.affix-line {
  height: 100px;
}