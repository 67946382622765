[data-v-4c645c2a] .el-tabs__content {
  padding: 0px !important;
  background-color: transparent;
}
[data-v-4c645c2a] .avue-crud .el-table {
  height: calc(100vh - 315px) !important;
  max-height: calc(100vh - 315px) !important;
}
[data-v-4c645c2a] .bjs-powered-by {
  display: none;
}
.iframeBox[data-v-4c645c2a] {
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  padding: 12px;
  z-index: 99;
}
.iframe[data-v-4c645c2a] {
  width: 100%;
  height: calc(100% - 28px);
  border: none;
}