@charset "UTF-8";
[data-v-2b3f588d] .el-input__inner {
  background-color: transparent !important;
  border: 0;
  font-family: "PingFangSt";
  color: #fff;
  font-size: 1vw;
  font-style: italic;
  text-shadow: 0 0 12px rgba(0, 106, 255, 0.8);
  padding: 5px;
  font-weight: bold;
  padding-right: 35px;
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
[data-v-2b3f588d] .el-select__caret {
  color: #fff !important;
  font-size: 1.563vw !important;
}
[data-v-2b3f588d] .el-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#app[data-v-2b3f588d] {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}
#app .main[data-v-2b3f588d] {
  width: 100vw;
  height: 100vh;
  background: url(../../static/img/backgroundOld.1d1f8e61.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 5.208vw;
}
#app .main .fullScreen[data-v-2b3f588d] {
  position: fixed;
  top: 1.563vw;
  right: 2.344vw;
  font-size: 18px;
  color: #E3F3FF;
}
#app .main > div[data-v-2b3f588d] {
  position: relative;
  z-index: 2;
}
#app .main .header[data-v-2b3f588d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #CDD6e3;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 3.125vw;
}
#app .main .header .dateTime[data-v-2b3f588d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
#app .main .header .dateTime span[data-v-2b3f588d]:first-child {
  font-size: 0.938vw;
}
#app .main .header .dateTime span[data-v-2b3f588d]:last-child {
  font-size: 0.625vw;
}
#app .main .header .statistics[data-v-2b3f588d] {
  width: 10.677vw;
  height: 3.125vw;
  margin-left: 3.125vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .main .header .statistics > div[data-v-2b3f588d]:first-child {
  width: 3.125vw;
  height: 3.125vw;
}
#app .main .header .statistics > div:first-child img[data-v-2b3f588d] {
  width: 100%;
  height: 100%;
}
#app .main .header .statistics > div[data-v-2b3f588d]:last-child {
  width: 7.552vw;
  height: 3.125vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
#app .main .header .statistics > div:last-child span[data-v-2b3f588d]:first-child {
  font-size: 0.729vw;
  color: #E3F3FF;
}
#app .main .header .statistics > div:last-child span[data-v-2b3f588d]:last-child {
  font-size: 1.458vw;
}
#app .main .header .statistics > div:last-child span:last-child span[data-v-2b3f588d] {
  font-size: 0.573vw;
  color: #E3F3FF;
}
#app .main .header .switchProject[data-v-2b3f588d] {
  width: 13.021vw;
  height: 2.083vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 3.125vw;
}
#app .main .body[data-v-2b3f588d] {
  height: calc(100% - 3.125vw);
  padding: 1.042vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app .main .body > div[data-v-2b3f588d]:first-child {
  margin-right: 1.3889vh;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#app .main .body > div:first-child > div[data-v-2b3f588d] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 1.3889vh;
}
#app .main .body > div:first-child > div[data-v-2b3f588d]:last-child {
  margin-bottom: 0;
}
#app .main .body > div[data-v-2b3f588d]:nth-child(2) {
  width: 50%;
  margin-right: 1.3889vh;
}
#app .main .body > div:nth-child(2) .earthMap[data-v-2b3f588d] {
  position: relative;
  background: url(../../static/img/lightRing.7f9a444c.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  width: 100%;
  height: 66.1%;
  margin-bottom: 1.3889vh;
}
#app .main .body > div:nth-child(2) .earthMap > div[data-v-2b3f588d] {
  width: 100%;
  height: 100%;
}
#app .main .body > div:nth-child(2) .center-bottom[data-v-2b3f588d] {
  width: 100%;
  height: calc(33.7% - 1.3889vh);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.3889vh;
}
#app .main .body > div:nth-child(2) .center-bottom > div[data-v-2b3f588d]:first-child {
  margin-right: 1.3889vh;
}
#app .main .body > div[data-v-2b3f588d]:last-child {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#app .main .body > div:last-child > div[data-v-2b3f588d] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 1.3889vh;
}
#app .main .body > div:last-child > div[data-v-2b3f588d]:last-child {
  margin-bottom: 0;
}
#app .rainBox[data-v-2b3f588d] {
  position: absolute;
  width: 100vw;
  height: 100vw;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
}
#app .rainBox canvas[data-v-2b3f588d] {
  position: absolute;
  bottom: 0;
}
.echartsModelS > .echartsModelS-chart[data-v-2b3f588d] {
  width: 100%;
  height: calc(100% - 4.1667vh);
  background-image: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@-webkit-keyframes shan-2b3f588d {
0% {
    opacity: 1;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}
@keyframes shan-2b3f588d {
0% {
    opacity: 1;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}