.columnDesign-box[data-v-7278a679] {
  width: 100%;
  height: 100%;
  position: relative;
}
.columnDesign-box .head-tabs[data-v-7278a679] {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 350px);
  height: 42px;
  border-bottom: 1px solid #dcdfe6;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0 10px;
  z-index: 100;
  border-radius: 4px 4px 0 0;
}
.columnDesign-box .head-tabs .el-button + .el-button[data-v-7278a679] {
  margin-left: 15px;
}
.columnDesign-box .head-tabs .unActive-btn[data-v-7278a679] {
  color: #606266 !important;
}
.columnDesign-box .head-tabs .unActive-btn[data-v-7278a679]:hover {
  color: #1890ff !important;
}
.columnDesign-box .head-tabs[data-v-7278a679] i {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}