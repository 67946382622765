.common_tree_handle .el-tree[data-v-c6a5929a] {
  /* height:100% !important; */
  overflow-y: scroll;
}
[data-v-c6a5929a] .el-tree-node__content {
  line-height: 26px;
}
.el-popover[data-v-c6a5929a] {
  min-width: 80px !important;
}
.rightBtn[data-v-c6a5929a] {
  width: 48px;
  height: 96px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: absolute;
  left: 264px;
  top: calc(50vh - 148px);
  z-index: 99;
}