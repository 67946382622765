.main-content[data-v-72a75b68] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.headers[data-v-72a75b68] {
  background: #ffffff;
  padding: 14px 12px;
}
.headers[data-v-72a75b68] .el-button {
  height: 28px;
}
.el-button + .el-button[data-v-72a75b68] {
  margin-left: 5px !important;
}
[data-v-72a75b68] .bjs-powered-by {
  display: none;
}