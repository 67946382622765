
.tableShowRadio[data-v-06c25ef8] {
position: absolute;
right: 12px;
top: 62px;
}
.courseCoverImg[data-v-06c25ef8] {
width: 80px;
padding: 5px;
}
