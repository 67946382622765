.cardLayout[data-v-63aff3e4] {
  padding: 0 12px;
  margin-top: 12px;
}
.cardListBig[data-v-63aff3e4] {
  width: 100%;
  height: calc(100vh - 278px);
  border: 1px solid #cccccc;
  margin-bottom: 12px;
  overflow: auto;
}
.cardList[data-v-63aff3e4] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cardBox[data-v-63aff3e4] {
  width: calc(25% - 14px);
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin: 6px;
  padding-top: 6px;
}
.cardImg[data-v-63aff3e4] {
  width: 96%;
  height: 180px;
  margin: 0 auto;
  display: block;
}
.cardTime[data-v-63aff3e4] {
  width: 96%;
  margin: 6px auto;
  font-size: 14px;
  color: #333333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.cardLine[data-v-63aff3e4] {
  width: 100%;
  border-bottom: 1px solid #cccccc;
}
.cardBtn[data-v-63aff3e4] {
  padding: 6px 0;
  width: 96%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.cardBtn .cardBtn-status[data-v-63aff3e4] {
  padding: 4px 10px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.cardBtn .shengxiao[data-v-63aff3e4] {
  background-color: #7ac756;
}
.cardBtn .shixiao[data-v-63aff3e4] {
  background-color: #85888e;
}
.flex-item[data-v-63aff3e4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}