@charset "UTF-8";
[data-v-bdc0f23a] .el-textarea__inner {
  height: 70vh;
}
.dialog-footer[data-v-bdc0f23a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}
.form-container .el-form-item__content[data-v-bdc0f23a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; /* 垂直居中对齐 */
}
.form-container .el-input__inner[data-v-bdc0f23a] {
  height: 40px; /* 调整输入框高度 */
  line-height: 40px; /* 调整行高以匹配输入框高度 */
}
.form-container .el-form-item[data-v-bdc0f23a] {
  margin-bottom: 20px; /* 调整表单项之间的间距 */
}
[data-v-bdc0f23a] .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item[data-v-bdc0f23a] {
  font-size: 24px;
  margin-left: 6px;
}
.iconLine[data-v-bdc0f23a] {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}
.borderLine[data-v-bdc0f23a] {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}