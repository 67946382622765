
.root[data-v-cd07330c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-content: center;
  margin-top: 3rem;
  position: relative;
}
.container-shell[data-v-cd07330c] {
  position: relative;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background: hsla(0, 0%, 50%, 0.5);
  padding: 30px 4px 10px 4px;
  /* border: 2px solid black; */
  width: auto;
  position: relative;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px;
          box-shadow: 0 10px 20px;
}
.container-shell-title[data-v-cd07330c] {
  position: absolute;
  color: darkgray;
  top: 4px;
  left: 10px;
  text-shadow: 1px 1px black;
}
.tag-version[data-v-cd07330c] {
}
#container[data-v-cd07330c] {
  background: rgba(13, 14, 27, 0.7);
  width: 900px;
  height: 568px;
}
.input[data-v-cd07330c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 3px;
  color: white;
  place-content: stretch;
  border-bottom: 1px solid #ccc;
}
.input-wrap[data-v-cd07330c] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.input2[data-v-cd07330c] {
  bottom: 0px;
}
.input input[type='input'][data-v-cd07330c] {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
}
.input-annnie[data-v-cd07330c] {
  position: relative;
  height: 20px;
  overflow: hidden;
}
.input-tips[data-v-cd07330c] {
  position: absolute;
  width: 100%;
  text-align: right;
  top: 0;
  left: 0;
  -webkit-animation: ani 20s linear infinite;
          animation: ani 20s linear infinite;
}
.err[data-v-cd07330c] {
  position: absolute;
  top: 40px;
  left: 10px;
  color: red;
}
.span-row[data-v-cd07330c] {
  display: inline-block;
  margin-right: 10px;
}
.option[data-v-cd07330c] {
  position: absolute;
  top: 4px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
}
.option span[data-v-cd07330c] {
  color: white;
}
.page[data-v-cd07330c] {
  background-repeat: no-repeat;
  background-position: top;
}
.show-message[data-v-cd07330c] {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 31px;
  padding: 5px;
  font-size: 10px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}
@media (max-width: 720px) {
#container[data-v-cd07330c] {
    width: 90vw;
    height: 52.7vw;
}
}
