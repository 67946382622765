.mainReportBox[data-v-d3eee456] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgb(244, 245, 246);
  width: 100%;
  height: 100%;
}
.topReportBox[data-v-d3eee456] {
  width: 100%;
  position: relative;
  height: 46px;
  background-color: #F2F3F5;
  border-bottom: 1px solid rgb(204, 204, 204);
}
.closeBtn[data-v-d3eee456] {
  position: absolute;
  right: 12px;
  top: 5px;
}
.closeBtn[data-v-d3eee456]:hover {
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
}
.leftReportTitle[data-v-d3eee456] {
  width: 280px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  color: #333333;
  background-color: #F2F3F5;
  font-size: 20px;
  letter-spacing: 10px;
  border-right: 1px solid rgb(204, 204, 204);
  position: absolute;
  z-index: 99;
}
.rightReportSidder[data-v-d3eee456] {
  width: calc(100% - 391px);
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #F2F3F5;
  position: absolute;
  left: 280px;
}
.menu-list[data-v-d3eee456] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
}
[data-v-d3eee456] .common_tree_handle .el-tree {
  height: calc(100vh - 148px) !important;
}
.el-menu[data-v-d3eee456] {
  background-color: transparent;
}
.rightReportSidder .el-menu-item[data-v-d3eee456] {
  color: #333333 !important;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  background-color: #F2F3F5 !important;
  border-bottom: 2px solid #F2F3F5 !important;
}
.el-menu--horizontal > .el-menu-item[data-v-d3eee456]:hover {
  background-color: unset !important;
}
[data-v-d3eee456] .el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  color: #333333 !important;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  background-color: #F2F3F5;
}
[data-v-d3eee456] .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #FFFFFF !important;
}
[data-v-d3eee456] .el-menu .el-submenu__title i:last-child {
  display: none;
}
.el-menu--horizontal .el-menu .el-menu-item[data-v-d3eee456] {
  height: 46px !important;
  line-height: 46px !important;
  background-color: #FFFFFF !important;
}
.oneChildNameBox[data-v-d3eee456] {
  height: 46px !important;
  line-height: 46px !important;
  font-size: 14px;
}
.oneChildName[data-v-d3eee456] {
  color: #333333 !important;
}
.rightTags[data-v-d3eee456] {
  width: 100%;
  height: 46px;
  background-color: #FFFFFF;
  margin-bottom: 1px;
}
.rightTags .el-tabs--card .el-tabs__header[data-v-d3eee456] {
  border: none;
}
[data-v-d3eee456] .rightTags .el-tabs__item {
  color: #909399;
  height: 30px;
  line-height: 30px;
  border: 1px solid rgb(204, 204, 204) !important;
  border-radius: 4px;
  margin-right: 8px;
}
[data-v-d3eee456] .rightTags .el-tabs__item.is-active {
  color: #333333;
  background-color: #F2F3F5;
}
[data-v-d3eee456] .el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 30px;
  padding: 8px 16px;
  margin: 0;
}
[data-v-d3eee456] .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}
[data-v-d3eee456] .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
[data-v-d3eee456] .el-tabs__content {
  background-color: transparent !important;
}
.reportViewImg[data-v-d3eee456] {
  width: 80%;
  height: calc(100% - 300px);
  margin: 100px auto;
  display: block;
}