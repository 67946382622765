
.headers[data-v-64317d68] {
    background: #FFFFFF;
    padding: 14px 12px;
}
.headers[data-v-64317d68] .el-button {
    height: 28px;
}
[data-v-64317d68] .el-button+.el-button {
  margin-left: 0px;
}
