.riskIdentification[data-v-b287ecbe] {
  background-color: #FFFFFF;
}
.riskIdentification .common_tree_handle .el-tree[data-v-b287ecbe] {
  height: calc(100vh - 278px) !important;
}
.riskIdentification .table-box[data-v-b287ecbe] {
  width: 100%;
}
.form-base[data-v-b287ecbe] {
  margin-top: 16px;
  margin-right: 10px;
}
[data-v-b287ecbe] .introduceTable .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
.introduceTree[data-v-b287ecbe] .common_tree_handle .el-tree {
  height: calc(100vh - 278px) !important;
}
[data-v-b287ecbe] .el-tabs__header {
  margin-bottom: 0;
}
[data-v-b287ecbe] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
[data-v-b287ecbe] .el-tabs__content {
  padding: 0 !important;
}