.riskIdentification[data-v-df658ff2] {
  background-color: #FFFFFF;
}
.riskIdentification .common_tree_handle .el-tree[data-v-df658ff2] {
  height: calc(100vh - 278px) !important;
}
.riskIdentification .table-box[data-v-df658ff2] {
  width: calc(100% - 281px);
}
[data-v-df658ff2] .el-tabs {
  width: 100%;
}
[data-v-df658ff2] .el-tabs .el-tabs__nav-scroll {
  width: 100% !important;
}
[data-v-df658ff2] .el-tabs__nav {
  margin-left: 20px;
}
[data-v-df658ff2] .introduceTable .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
.introduceTree[data-v-df658ff2] .common_tree_handle .el-tree {
  height: calc(100vh - 278px) !important;
}
[data-v-df658ff2] .el-tabs__header {
  margin-bottom: 0;
}
[data-v-df658ff2] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
[data-v-df658ff2] .el-tabs__content {
  padding: 0 !important;
}
.el-input-number[data-v-df658ff2] {
  width: 100%;
}
.logic-box[data-v-df658ff2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
[data-v-df658ff2] .form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-df658ff2] .form-box .avue-form {
  width: 60% !important;
}