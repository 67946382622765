@charset "UTF-8";
.class-list[data-v-1e5ad0b6] {
  -webkit-animation-name: scroll;
          animation-name: scroll;
  -webkit-animation-duration: var(--animation-duration, 10s);
          animation-duration: var(--animation-duration, 10s);
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.prj-index[data-v-1e5ad0b6] {
  height: 100%;
}
.card-b-tips[data-v-1e5ad0b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.split-line[data-v-1e5ad0b6] {
  padding: 0 10px;
}
.home-line-content[data-v-1e5ad0b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
  height: calc(50% - 74px);
}
.block-box[data-v-1e5ad0b6] {
  width: calc(50% - 10px);
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
}
.block-box .content[data-v-1e5ad0b6] {
  height: calc(35vh - 50px);
}
.block-box .title[data-v-1e5ad0b6] {
  padding-top: 10px;
  position: relative;
}
.block-box .title .icon[data-v-1e5ad0b6] {
  position: absolute;
  left: 0;
  top: 12px;
  width: 4px;
  height: 20px;
  background: #65abf6;
}
.block-box .title .text[data-v-1e5ad0b6] {
  margin-left: 20px;
}
.block-box .title .inNum[data-v-1e5ad0b6],
.block-box .title .outNum[data-v-1e5ad0b6] {
  font-size: 14px;
  font-weight: bold;
}
.block-box .title .flex-text[data-v-1e5ad0b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.class-list[data-v-1e5ad0b6] {
  overflow: auto;
  scrollbar-width: none;
  height: 100%;
  /* 对于Firefox */
}

/* 针对WebKit浏览器，如Chrome和Safari */
.class-list[data-v-1e5ad0b6]::-webkit-scrollbar {
  display: none;
  /* 隐藏滚动条 */
}
.class-list[data-v-1e5ad0b6] {
  height: 100%;
}
.class-list .list-item[data-v-1e5ad0b6] {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 40px);
}
.class-list .list-item .list-content[data-v-1e5ad0b6] {
  margin-left: 20px;
  width: calc(100% - 210px);
}
.class-list .list-item .list-content .meetingTitle[data-v-1e5ad0b6] {
  font-weight: bold;
}
.class-list .list-item .list-content .meetingContents[data-v-1e5ad0b6] {
  margin-top: 10px;
  font-size: 14px;
  display: -webkit-box;
  /* 启用弹性盒子布局模型 */
  -webkit-box-orient: vertical;
  /* 子元素垂直排列 */
  -webkit-line-clamp: 3;
  /* 限制在3行内 */
  overflow: hidden;
  /* 隐藏超出盒子的内容 */
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
  max-height: 70px;
  /* 设置最大高度 */
  line-height: 20px;
  /* 行高 */
  width: 100%;
  /* 设置宽度 */
  /* 设定最大高度 */
  /* 当内容超出元素时显示滚动条 */
}
.home-statistics[data-v-1e5ad0b6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.home-statistics .card-item[data-v-1e5ad0b6] {
  color: #fff;
  width: calc(20% - 20px);
  position: relative;
}
.home-statistics .card-item .svg-icon[data-v-1e5ad0b6] {
  position: absolute;
  right: 40px;
  top: 20px;
}
.home-statistics .card-item .card[data-v-1e5ad0b6] {
  padding: 10px 0 0 0;
  width: 100%;
}
.home-statistics .card-item .card .card-content[data-v-1e5ad0b6] {
  margin-top: 10px;
}
.home-statistics .card-item .card .card-b-tips[data-v-1e5ad0b6] {
  margin-top: 10px;
  font-size: 12px;
}
.home-statistics .card-item .card .unit[data-v-1e5ad0b6] {
  margin-left: 4px;
}
.home-statistics .card-item .card .num[data-v-1e5ad0b6] {
  font-size: 20px;
  color: #fff;
  text-decoration: underline;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}
[data-v-1e5ad0b6].el-card__body {
  height: 100%;
}
.todo-items[data-v-1e5ad0b6] {
  background: -webkit-gradient(linear, right top, left top, from(#33c7ee), to(#14b0d8));
  background: linear-gradient(to left, #33c7ee, #14b0d8);
}
.todo-items .num-box[data-v-1e5ad0b6] {
  margin-top: 40px;
}
.security-inspection[data-v-1e5ad0b6] {
  background: -webkit-gradient(linear, right top, left top, from(#fdba72), to(#f2a858));
  background: linear-gradient(to left, #fdba72, #f2a858);
}
.safety-training[data-v-1e5ad0b6] {
  background: -webkit-gradient(linear, right top, left top, from(#4bdbdb), to(#3bcccc));
  background: linear-gradient(to left, #4bdbdb, #3bcccc);
}
.safe-production[data-v-1e5ad0b6] {
  background: -webkit-gradient(linear, right top, left top, from(#65abf6), to(#4696ea));
  background: linear-gradient(to left, #65abf6, #4696ea);
}
.hazard-source-warning[data-v-1e5ad0b6] {
  background: -webkit-gradient(linear, right top, left top, from(#fd8866), to(#eb6a45));
  background: linear-gradient(to left, #fd8866, #eb6a45);
}