.dashboard-container[data-v-1838a5e8] {
  width: 100%;
  height: 100%;
  background: #ebeef5;
  position: relative;
}
.dashboard-container .custom-page[data-v-1838a5e8] {
  width: 100%;
  height: 100%;
}
.dashboard-container[data-v-1838a5e8] .layout-area {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.dashboard-container .setting-btn[data-v-1838a5e8] {
  position: absolute;
  top: 200px;
  right: -10px;
  height: 40px;
  width: 40px;
  text-align: center;
  padding: 0;
  border-radius: 20px 0 0 20px;
  z-index: 100;
}
.dashboard-container .setting-btn[data-v-1838a5e8] i {
  font-size: 20px;
  font-weight: 580;
}
.dashboard-container[data-v-1838a5e8] .vue-grid-layout {
  margin: -10px;
}
.dashboard-container[data-v-1838a5e8] .el-scrollbar__view {
  overflow: hidden;
}