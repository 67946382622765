[data-v-f894013a] .el-tabs__content {
  padding: 0px !important;
  background-color: transparent;
}
[data-v-f894013a] .avue-crud .el-table {
  height: calc(100vh - 315px) !important;
  max-height: calc(100vh - 315px) !important;
}
[data-v-f894013a] .bjs-powered-by {
  display: none;
}