.pane-calc-preview[data-v-4d108ef9] {
  padding: 0 15px;
  cursor: pointer;
  min-height: 32px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  font-size: 13px;
}
.pane-calc-preview .placeholder-text[data-v-4d108ef9] {
  color: #c0c4cc;
}
.pane-calc-preview .calc-btn[data-v-4d108ef9]:first-child {
  margin-left: 0;
}
.calc-btn[data-v-4d108ef9] {
  padding: 4px 8px;
  margin: 0 6px;
  background: #dcdfe6;
  cursor: pointer;
}
.calc-btn.error[data-v-4d108ef9] {
  background: #f56c6c;
  color: white;
}
.calc-btn[data-v-4d108ef9]:hover {
  background: #f5f5f5;
}
.calc-dialog[data-v-4d108ef9] .el-dialog__body {
  padding-top: 0;
}
.calc-dialog .calc-box[data-v-4d108ef9] {
  font-size: 12px;
  line-height: 2;
}
.calc-dialog .calc-box .calc-tip[data-v-4d108ef9] {
  margin: 10px 0;
  font-size: 12px;
  color: #aaa;
}
.calc-dialog .calc-box .numBtn[data-v-4d108ef9] {
  width: 110px;
  line-height: 2.5;
  overflow: hidden;
  padding-left: 3px;
}
.calc-dialog .calc-box .comBtn .calc-btn[data-v-4d108ef9] {
  width: 22px;
  padding: 0;
  text-align: center;
  line-height: 22px;
  display: inline-block;
}
.calc-dialog .calc-box .calc-preview[data-v-4d108ef9] {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  min-height: 60px;
  padding: 4px 10px;
  position: relative;
}
.calc-dialog .calc-box .calc-preview.error[data-v-4d108ef9] {
  border: 1px solid red;
}
.calc-dialog .calc-box .calc-preview .preview-actions[data-v-4d108ef9] {
  position: absolute;
  bottom: 0;
  right: 0;
}
.calc-dialog .calc-box .calc-preview .preview-actions > i[data-v-4d108ef9] {
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
}
.calc-dialog .calc-box .calc-preview .preview-actions > i[data-v-4d108ef9]:hover {
  color: red;
}