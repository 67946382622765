[data-v-a9fd1f20] .el-tabs__content {
  margin-top: 30px;
}
[data-v-a9fd1f20] .el-drawer__header {
  margin-left: 25px;
  margin-top: 16px;
}
.el-autocomplete[data-v-a9fd1f20] {
  width: 100%;
}
.el-steps[data-v-a9fd1f20] {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 16px;
}
.el-icon-circle-plus[data-v-a9fd1f20] {
  font-size: 25px;
  margin-top: 3px;
}
[data-v-a9fd1f20] .form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-a9fd1f20] .form-box .avue-form {
  width: 60% !important;
}
[data-v-a9fd1f20] .form-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 5px;
}