.riskItemView-checklist[data-v-6973375d] {
  height: 100%;
  width: 100%;
}
.riskItemView-checklist .table-box[data-v-6973375d] {
  height: calc(100% - 56px) !important;
}
.riskItemView-checklist .calcWidth[data-v-6973375d] {
  width: calc(100% - 280px);
}
.riskItemView-checklist .Width[data-v-6973375d] {
  width: 100%;
}
.riskItemView-checklist #gridLayout[data-v-6973375d] {
  height: calc(100% - 100px);
}
.riskItemView-checklist[data-v-6973375d] .common_tree_handle .el-tree {
  height: calc(100vh - 260px) !important;
}
.riskItemView-checklist[data-v-6973375d] .avue-crud .el-table {
  height: calc(100vh - 312px) !important;
  max-height: calc(100vh - 312px) !important;
}
.riskItemView-checklist .charts-box[data-v-6973375d] {
  padding-top: 15px;
  padding-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.riskItemView-checklist .charts-box .no-border-right[data-v-6973375d] {
  border-right: 0;
}
.riskItemView-checklist .charts-box[data-v-6973375d] {
  height: 260px;
}
.riskItemView-checklist .charts-icon[data-v-6973375d] {
  width: calc(33.3333333333% - 16px);
  height: 260px;
}
.riskItemView-checklist .pie-box[data-v-6973375d] {
  margin-top: 15px;
}
.riskItemView-checklist .pie[data-v-6973375d] {
  width: 100%;
}
.head-titles[data-v-6973375d] {
  line-height: 28px;
}
[data-v-6973375d] .el-tabs__header {
  margin-bottom: 0;
}
[data-v-6973375d] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
[data-v-6973375d] .el-tabs__content {
  padding: 0 !important;
}