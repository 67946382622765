.printData-container[data-v-402f5fdf] {
  padding: 0;
}
.printData-container .el-tabs[data-v-402f5fdf] {
  height: 100%;
}
.printData-container[data-v-402f5fdf] .el-table thead.is-group th {
  background: #fff !important;
}
.printData-container .prtBtn[data-v-402f5fdf] {
  position: absolute;
  right: 28px;
  top: 4px;
  z-index: 1;
}
.printData-container[data-v-402f5fdf] .el-tabs__content {
  height: calc(100% - 39px);
}
.printData-container[data-v-402f5fdf] .el-tabs__content .el-tab-pane {
  height: 100%;
}
.printData-container[data-v-402f5fdf] .storage {
  width: 800px;
  margin: 0 auto;
}
.printData-container[data-v-402f5fdf] .storage .el-table thead tr,
.printData-container[data-v-402f5fdf] .storage .el-table thead tr th {
  color: #606266;
}
.printData-container[data-v-402f5fdf] .el-table {
  width: 800px;
  margin: 0 auto;
}
.printData-container[data-v-402f5fdf] .el-table thead {
  color: #606266;
}
.printData-container[data-v-402f5fdf] .bill {
  width: 800px;
  margin: 0 auto;
  text-align: center;
}
.printData-container[data-v-402f5fdf] .bill .bill-title {
  color: #606266;
}