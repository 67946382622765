input[data-v-1f9ba22e],
textarea[data-v-1f9ba22e] {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
.editor-tabs[data-v-1f9ba22e] {
  background: #121315;
}
.editor-tabs .el-tabs__header[data-v-1f9ba22e] {
  margin: 0;
  border-bottom-color: #121315;
}
.editor-tabs .el-tabs__header .el-tabs__nav[data-v-1f9ba22e] {
  border-color: #121315;
}
.editor-tabs .el-tabs__item[data-v-1f9ba22e] {
  height: 32px;
  line-height: 32px;
  color: #888a8e;
  border-left: 1px solid #121315 !important;
  background: #363636;
  margin-right: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.editor-tabs .el-tabs__item.is-active[data-v-1f9ba22e] {
  background: #1e1e1e;
  border-bottom-color: #1e1e1e !important;
  color: #fff;
}
.editor-tabs .el-icon-edit[data-v-1f9ba22e] {
  color: #f1fa8c;
}
.editor-tabs .el-icon-document[data-v-1f9ba22e] {
  color: #a95812;
}
.right-scrollbar .el-scrollbar__view[data-v-1f9ba22e] {
  padding: 10px;
}
.right-scrollbar .el-scrollbar__view .el-input-number[data-v-1f9ba22e] {
  width: 100%;
}
.el-scrollbar__wrap[data-v-1f9ba22e] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__header[data-v-1f9ba22e] {
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__item[data-v-1f9ba22e] {
  width: 33.33%;
  text-align: center;
}
.center-tabs .el-tabs__nav[data-v-1f9ba22e] {
  width: 100%;
}
.reg-item[data-v-1f9ba22e] {
  padding: 12px 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;
}
.reg-item .close-btn[data-v-1f9ba22e] {
  position: absolute;
  right: -6px;
  top: -6px;
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  font-size: 12px;
}
.reg-item .close-btn[data-v-1f9ba22e]:hover {
  background: rgba(210, 23, 23, 0.5);
}
.reg-item + .reg-item[data-v-1f9ba22e] {
  margin-top: 18px;
}
.action-bar .el-button + .el-button[data-v-1f9ba22e] {
  margin-left: 15px;
}
.action-bar i[data-v-1f9ba22e] {
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.main .el-scrollbar__view[data-v-1f9ba22e] {
  overflow-x: hidden;
}
.main .el-rate[data-v-1f9ba22e] {
  display: inline-block;
  vertical-align: text-top;
}
.main .el-upload__tip[data-v-1f9ba22e] {
  line-height: 1.2;
}