.add[data-v-7adf103c] {
  height: 100%;
}
[data-v-7adf103c] .tree-box .common_tree_handle {
  height: 100%;
}
[data-v-7adf103c] .tree-box .common_tree_handle .el-tree {
  height: calc(100% - 112px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-v-7adf103c] .tree-box #gridLayout {
  height: calc(100% - 90px) !important;
}
[data-v-7adf103c] .tree-box .avue-crud {
  height: 100% !important;
}
[data-v-7adf103c] .tree-box .avue-crud .el-card {
  height: 100% !important;
}
[data-v-7adf103c] .tree-box .avue-crud .el-card__body {
  height: 100% !important;
}
[data-v-7adf103c] .tree-box .avue-crud .el-card__body .el-form {
  height: 100% !important;
}
[data-v-7adf103c] .tree-box .avue-crud .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-7adf103c] .tree-box .avue-crud .el-table__empty-text {
  height: 100% !important;
}
.el-main[data-v-7adf103c] {
  height: 100%;
  overflow: hidden;
}
[data-v-7adf103c] .DeptDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}