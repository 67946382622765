
.tableShowRadio[data-v-97ca7604] {
  position: absolute;
  right: 12px;
  top: 62px;
}
.courseCoverImg[data-v-97ca7604] {
  width: 80px;
  padding: 5px;
}
