.tem_main[data-v-5baa9c49] {
  width: 800px;
  margin: 0 auto;
}
.content1[data-v-5baa9c49] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.content1 .item[data-v-5baa9c49] {
  width: 345px;
  padding: 8px 0;
  border-bottom: 1px solid #ebeef5;
}
.content1 .item span[data-v-5baa9c49] {
  font-size: 12px;
  padding: 10px 0;
  color: #606266;
}
.el-table[data-v-5baa9c49],
.content1[data-v-5baa9c49] {
  width: 720px !important;
  margin: 0 auto !important;
}
h1[data-v-5baa9c49],
h2[data-v-5baa9c49],
h3[data-v-5baa9c49] {
  text-align: center !important;
}
p[data-v-5baa9c49],
h4[data-v-5baa9c49] {
  width: 720px !important;
  margin: 0 auto !important;
}
p.title[data-v-5baa9c49] {
  border-bottom: 2px dashed #606266;
  line-height: 30px;
  font-size: 12px;
  color: #606266;
}
p.lip[data-v-5baa9c49] {
  padding: 20px 0;
  color: #606266;
}
.temdate[data-v-5baa9c49] {
  font-size: 12px;
  width: 602px;
  margin: 0 auto;
  text-align: right;
  margin: 20px 60px;
  color: #606266;
}
.seal[data-v-5baa9c49] {
  font-size: 12px;
  width: 480px;
  margin: 0 auto;
  text-align: right;
  margin: 10px 120px;
  padding-bottom: 40px;
  color: #606266;
}
.offer-container[data-v-5baa9c49] {
  height: 100%;
  overflow: auto;
}
.offer-container .tem_list[data-v-5baa9c49] {
  width: 800px;
  height: 100%;
  margin: 0 auto;
  background: #fff;
}
.offer-container .tem_list .tem_main[data-v-5baa9c49] {
  width: 90%;
  margin: 0 auto;
  font-size: 12px;
  padding-top: 10px;
  color: #606266;
}
.offer-container .tem_list .tem_main h1[data-v-5baa9c49],
.offer-container .tem_list .tem_main h2[data-v-5baa9c49] {
  text-align: center;
  color: #606266;
}
.offer-container .tem_list .tem_main .title[data-v-5baa9c49] {
  border-bottom: 2px dashed #606266;
  line-height: 30px;
  font-size: 12px;
  color: #606266;
}
.offer-container .tem_list .tem_main[data-v-5baa9c49] .el-table thead tr th {
  background-color: #fff;
  color: #606266;
}
.offer-container .tem_list .tem_main .lip[data-v-5baa9c49] {
  padding: 20px 0;
  color: #606266;
}
.offer-container .tem_list .tem_main .demo-form-inline[data-v-5baa9c49] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.offer-container .tem_list .tem_main .el-form-item--mini.el-form-item[data-v-5baa9c49],
.offer-container .tem_list .tem_main .el-form-item--small.el-form-item[data-v-5baa9c49] {
  border-bottom: 1px solid #ebeef5;
  width: 48%;
  margin: 0;
}
.offer-container .tem_list .tem_main .el-form-item--mini.el-form-item[data-v-5baa9c49] .el-input__inner,
.offer-container .tem_list .tem_main .el-form-item--small.el-form-item[data-v-5baa9c49] .el-input__inner {
  border: 0 !important;
  padding: 0;
  width: 220px;
  font-size: 12px;
}
.offer-container .tem_list .tem_main .el-form-item--mini.el-form-item[data-v-5baa9c49] .el-form-item__label,
.offer-container .tem_list .tem_main .el-form-item--small.el-form-item[data-v-5baa9c49] .el-form-item__label {
  font-size: 12px;
}
.offer-container .tem_list .tem_main .temdate[data-v-5baa9c49] {
  text-align: right;
  margin: 20px 60px;
}
.offer-container .tem_list .tem_main .seal[data-v-5baa9c49] {
  text-align: right;
  margin: 10px 120px;
  padding-bottom: 40px;
}