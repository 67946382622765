
/* 全局 */
#app[data-v-0387eb42] {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 10px;
}
/* 视频预览 */
#view1[data-v-0387eb42], #view2[data-v-0387eb42]{
    width: 400px;
    height: 300px;
    border: 1px solid red;
    margin-right: 5px;
}
/* 缩略图 */
#suoluetu[data-v-0387eb42]{
    width: 100%;
    height: 85px;
    border: 1px solid blue;
}
#suoluetu img[data-v-0387eb42]{
    margin-right: 10px;
}
/* 操作按钮 */
#myactive[data-v-0387eb42]{
    border: 1px solid yellowgreen;
    margin-top: 10px;
    padding: 10px 5px;
}
/* 操作日志 */
#mylog[data-v-0387eb42]{
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
    overflow: auto;
}
