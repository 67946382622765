.board[data-v-56a3aa2c] .el-drawer__body {
  overflow: hidden;
}
.board[data-v-56a3aa2c] .main {
  padding: 10px 20px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.board[data-v-56a3aa2c] .main .item .item-title {
  font-size: 12px;
  line-height: 30px;
  color: #999;
}
.board[data-v-56a3aa2c] .main .item .item-list {
  font-size: 14px;
  color: #707070;
}
.board[data-v-56a3aa2c] .main .item .item-list .item-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 45px;
  cursor: pointer;
}
.board[data-v-56a3aa2c] .main .item .item-list .item-list-item i {
  font-size: 20px;
  color: #bdbdbd;
}
.board[data-v-56a3aa2c] .main .item .item-list .item-list-item.active i {
  color: #1890ff;
}
.board[data-v-56a3aa2c] .main .noData-txt {
  font-size: 14px;
  color: #909399;
  line-height: 20px;
  text-align: center;
  padding-top: 10px;
}