.disFlex[data-v-7b6edb7a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
}
.card-pd-4-26[data-v-7b6edb7a] {
  width: calc(25% - 64px);
  padding: 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}
.card-pd-4-26-top[data-v-7b6edb7a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.card-pd-4-26-topLeft[data-v-7b6edb7a] {
  line-height: 61px;
  height: 61px;
  color: #15161A;
  font-size: 42px;
  font-weight: bold;
}
.card-pd-4-26-topLeft span[data-v-7b6edb7a] {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}
.card-pd-4-26-top img[data-v-7b6edb7a] {
  width: 61px;
  height: 61px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.card-pd-4-26-bottom[data-v-7b6edb7a] {
  width: 100%;
  margin-top: 48px;
  color: #15161A;
  font-size: 18px;
}
.card-pd-2[data-v-7b6edb7a] {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}
.card-pd-2-top[data-v-7b6edb7a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.card-pd-2-top-Title[data-v-7b6edb7a] {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}
.card-pd-2-top-btn[data-v-7b6edb7a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.card-pd-2-top-btn img[data-v-7b6edb7a] {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}
.card-pd-2-Bottom[data-v-7b6edb7a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 21px;
}
.card-pd-2-BotLitte[data-v-7b6edb7a] {
  width: calc(33.3% - 64px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 40px 26px;
}
.card-pd-2-BotLitte2[data-v-7b6edb7a] {
  width: calc(33.3% - 52px);
  border-radius: 8px;
  padding: 21px;
  background-color: #F5F7FA;
}
.botLitte2-number[data-v-7b6edb7a] {
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}
.botLitte2-number span[data-v-7b6edb7a] {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}
.botLitte2-title[data-v-7b6edb7a] {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
}
.botLitte2-info[data-v-7b6edb7a] {
  color: #15161A;
  font-size: 18px;
  margin-top: 13px;
}
.botLitte2-info span[data-v-7b6edb7a] {
  color: #FB3636;
}
.priceNumber[data-v-7b6edb7a] {
  width: 100%;
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
}
.priceNumber span[data-v-7b6edb7a] {
  font-weight: 400;
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}
.lineBox[data-v-7b6edb7a] {
  width: 40px;
  height: 3px;
  background-color: #FB3636;
  margin: 21px 0 26px;
}
.priceContent[data-v-7b6edb7a] {
  width: 100%;
  color: #15161A;
  font-size: 18px;
}
#chart-one[data-v-7b6edb7a] {
  width: 209px;
  height: 209px;
  margin-left: 93px;
}
.chart-one-info[data-v-7b6edb7a] {
  padding: 73px 0;
  margin-left: 58px;
}
.chart-one-info-box[data-v-7b6edb7a] {
  color: #1D2129;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.info-circle[data-v-7b6edb7a] {
  width: 10px;
  height: 10px;
  background: linear-gradient(26deg, #FF8181 16.57%, #FB3636 83.6%);
  border-radius: 50px;
  margin: 6px 6px 6px 0;
}
#chart-two[data-v-7b6edb7a] {
  width: 100%;
  height: 320px;
}
#chart-three[data-v-7b6edb7a] {
  width: 100%;
  height: 320px;
}
#chart-four[data-v-7b6edb7a] {
  width: 100%;
  height: 320px;
}
#chart-five[data-v-7b6edb7a] {
  width: 100%;
  height: 320px;
}
[data-v-7b6edb7a] .el-slider__runway.disabled .el-slider__bar {
  background-color: #FB3636;
}
[data-v-7b6edb7a] .el-slider__runway.disabled .el-slider__button {
  border-color: #FB3636;
}