
.outWardBox[data-v-2aa31f34] {
  width: calc(100% - 12px);
  padding: 0 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: auto;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  height: calc(100vh - 375px);
}
.inWardBox[data-v-2aa31f34] {
  width: 24%;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 5px #dde9ef;
          box-shadow: 0 0 5px #dde9ef;
  border: 1px solid #e7eff3;
  margin: 16px calc(0.5% - 1px) 0;
  text-align: center;
  border-radius: 10px;
  /*height: 40%;*/
  overflow: hidden;
}
.titleBox[data-v-2aa31f34] {
  font-size: 20px;
  color: #333333;
  font-weight: bolder;
  display: block;
}
.contentBox[data-v-2aa31f34] {
  color: #666666;
  font-size: 15px;
  text-align: center;
  margin: 10px 0.2% 0;
}
.contentBox div[data-v-2aa31f34] {
  width: 100px;
  text-align: right;
}
.bottomBox[data-v-2aa31f34] {
  width: calc(100% - 16px);
  background-color: rgba(64, 158, 255, 0.55);
  padding: 10px;
  text-align: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.stateBox[data-v-2aa31f34] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 10px 6px;
}
.el-dropdown + .el-dropdown[data-v-2aa31f34] {
  margin-left: 15px;
}
.el-icon-arrow-down[data-v-2aa31f34] {
  font-size: 12px;
}
