[data-v-33bcb427] #gridLayout {
  height: calc(100% - 138px) !important;
}
[data-v-33bcb427] .avue-crud {
  height: 100% !important;
}
[data-v-33bcb427] .avue-crud .el-card {
  height: 100% !important;
}
[data-v-33bcb427] .avue-crud .el-card__body {
  height: 100% !important;
}
[data-v-33bcb427] .avue-crud .el-card__body .el-form {
  height: 100% !important;
}
[data-v-33bcb427] .avue-crud .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-33bcb427] .avue-crud .avue-crud__pagination {
  height: auto !important;
}