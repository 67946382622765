.container[data-v-5b8dea85] {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.headerTabs[data-v-5b8dea85] {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.headerTabs-item[data-v-5b8dea85] {
  padding: 10px 5px;
  margin: 0 20px;
  cursor: pointer;
}
.headerTabs-item-active[data-v-5b8dea85] {
  color: #409eff !important;
  border-bottom: 2px solid #409eff !important;
}
.humanCentent[data-v-5b8dea85] {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.humanCentent-table[data-v-5b8dea85] {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.humanCentent-table-title[data-v-5b8dea85] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.titleItem[data-v-5b8dea85] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 20px;
}
.titleItem_lable[data-v-5b8dea85] {
  margin-right: 5px;
}
.titleItem_input[data-v-5b8dea85] {
  width: 150px;
}
.titleItem_picker[data-v-5b8dea85] {
  width: 140px;
}
.humanCententTableCentnet[data-v-5b8dea85] {
  margin-top: 15px;
}
[data-v-5b8dea85] .el-table--border {
  border-bottom: 1px solid #ebeef5 !important;
}
.StatisticalDataBox[data-v-5b8dea85] {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.StatisticalData[data-v-5b8dea85] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.StatisticalData_left[data-v-5b8dea85] {
  width: calc(50% - 5px);
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.StatisticalData_right[data-v-5b8dea85] {
  width: calc(50% - 5px);
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.StatisticalDataBot[data-v-5b8dea85] {
  width: 100%;
  height: 600px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 5px;
}
.echartsItem_title[data-v-5b8dea85] {
  width: 100%;
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.echartsItem_condition[data-v-5b8dea85] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.echartsItem_condition div[data-v-5b8dea85] {
  color: #555 !important;
  font-size: 12px !important;
}
[data-v-5b8dea85] .el-tabs__nav-scroll {
  padding: 0 10px !important;
}
[data-v-5b8dea85] .el-tabs__item.is-active {
  color: #303133 !important;
}
.echartsItem_text1[data-v-5b8dea85] {
  width: 100%;
  padding: 3px 0;
  text-align: center;
  color: #409eff;
}
.echartsItem_text[data-v-5b8dea85] {
  width: 100%;
  padding: 3px 0;
  text-align: center;
  color: #555;
  font-size: 12px;
}
#div_mobilizationTotalNumber[data-v-5b8dea85] {
  width: 100%;
  height: 380px;
}
#div_mobilizationTotalNumber div[data-v-5b8dea85] {
  width: 100% !important;
}
#div_postInputDistribution[data-v-5b8dea85] {
  width: 100%;
  height: 380px;
}
#div_postInputDistribution div[data-v-5b8dea85] {
  width: 100% !important;
}
#LDLQXecharts[data-v-5b8dea85] {
  width: 100%;
  height: 450px;
}
#LDLQXecharts div[data-v-5b8dea85] {
  width: 100% !important;
}
.echartsItem_table[data-v-5b8dea85] {
  width: 42.969vw;
  padding: 10px;
  height: 420px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.LDLQXecharts_table[data-v-5b8dea85] {
  width: 86.51vw;
  padding: 10px;
  height: 420px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-5b8dea85] .tree-disabled {
  color: #606060;
}