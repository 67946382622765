.field-dialog[data-v-62988d29] .el-dialog__body {
  height: 70vh;
  padding: 0 !important;
}
.field-dialog[data-v-62988d29] .el-dialog__body .main {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.field-dialog[data-v-62988d29] .el-dialog__body .el-table {
  border-top: 0;
}
.field-dialog[data-v-62988d29] .el-dialog__body .table-cell {
  font-size: 13px;
}