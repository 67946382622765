.inspection-dialog .inspection-dialog-main[data-v-8758dd4a] {
  padding: 10px;
  height: 500px;
}
[data-v-8758dd4a] #gridHeadLayout > div {
  padding: 0 !important;
}
[data-v-8758dd4a] .avue-crud .el-table {
  height: 320px !important;
  max-height: 320px !important;
}