p[data-v-6f7b1544] {
  margin: 0;
  padding: 0;
}
.pub-title[data-v-6f7b1544] {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}
.detail-content .detail-item[data-v-6f7b1544] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
}
.detail-content .detail-item label[data-v-6f7b1544] {
  color: #6d6b6b;
}
.inspection-info-contianer .info-item[data-v-6f7b1544] {
  padding: 10px;
}
.inspection-info-contianer .info-item .info-name[data-v-6f7b1544] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.count-text[data-v-6f7b1544] {
  padding: 10px;
}
.standard-container[data-v-6f7b1544] {
  margin-top: 10px;
}
.standard-container .standard-text[data-v-6f7b1544] {
  margin: 10px 0;
}
.standard-container .standard-img[data-v-6f7b1544] {
  margin-top: 10px;
}
.standard-container .standard-img label[data-v-6f7b1544] {
  vertical-align: top;
}
.blue[data-v-6f7b1544] {
  color: #0094ff;
}
.yellow[data-v-6f7b1544] {
  color: rgb(255, 116, 0);
}
.red[data-v-6f7b1544] {
  color: rgb(255, 0, 0);
}
.el-image[data-v-6f7b1544] {
  margin-left: 10px;
}