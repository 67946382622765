.inspection_records-index[data-v-65c7ffba] {
  height: 100% !important;
}
.inspection_records-index .el-main[data-v-65c7ffba] {
  height: 100%;
  overflow: hidden;
}
[data-v-65c7ffba] #gridLayout {
  height: calc(100% - 130px) !important;
}
[data-v-65c7ffba] .avue-crud {
  height: 100% !important;
}
[data-v-65c7ffba] .avue-crud .el-card {
  height: 100% !important;
}
[data-v-65c7ffba] .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-65c7ffba] .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-65c7ffba] .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
}