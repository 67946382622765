.main-content[data-v-190bd829] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
[data-v-190bd829] .common_tree_handle .el-tree {
  height: calc(100vh - 262px) !important;
}
[data-v-190bd829] .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}
[data-v-190bd829] .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}
[data-v-190bd829] .el-dialog__body {
  padding: 20px;
}
.kicket-content[data-v-190bd829] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.kicket-content .kicket[data-v-190bd829] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border: 1px solid #EDEDED;
  width: 250px;
  height: 90px;
  border-radius: 8px;
  margin: 10px 20px;
}
.kicket-content .kicket .img-item[data-v-190bd829] {
  width: 40px;
  height: 40px;
}
.kicket-content .kicket .text-item[data-v-190bd829] {
  color: #333333;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
[data-v-190bd829] .bjs-powered-by {
  display: none;
}
.avatar-uploader[data-v-190bd829] .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader[data-v-190bd829] .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon[data-v-190bd829] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar[data-v-190bd829] {
  width: 178px;
  height: 178px;
  display: block;
}