[data-v-5a362a4d] .paperCurd .avue-crud .el-table {
  height: calc(100vh - 406px) !important;
  max-height: calc(100vh - 406px) !important;
}
.knowledgeTree .common_tree_handle .el-tree[data-v-5a362a4d] {
  height: calc(100vh - 500px) !important;
}
[data-v-5a362a4d] .avue-tree {
  height: calc(100vh - 417px);
}
[data-v-5a362a4d] .serviceType .avue-crud .el-table .el-table__body-wrapper {
  height: unset !important;
}
[data-v-5a362a4d] .serviceType .avue-crud .el-table {
  height: auto !important;
}
[data-v-5a362a4d] .serviceType .avue-crud .el-table .avue-crud__empty {
  padding: 0;
}
[data-v-5a362a4d] .serviceType .avue-crud .el-table .el-empty {
  padding: 0;
}
[data-v-5a362a4d] .serviceType .avue-crud .el-table .el-empty__description {
  margin-top: 0;
}
[data-v-5a362a4d] .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 459px) !important;
  overflow-y: scroll;
}