.el-autocomplete[data-v-0d3530a0] {
  width: 100%;
}
.el-steps[data-v-0d3530a0] {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 16px;
}
.el-icon-circle-plus[data-v-0d3530a0] {
  font-size: 25px;
  margin-top: 3px;
}