p[data-v-71a0f9a8] {
  margin: 0;
  padding: 0;
}
.inspection-dialog .inspection-dialog-main[data-v-71a0f9a8] {
  padding: 10px;
  height: 500px;
}
.inspection-dialog .inspection-dialog-main .el-tabs[data-v-71a0f9a8] {
  margin: 20px 0 10px 0;
}
.chart-container[data-v-71a0f9a8] {
  text-align: center;
}
.chart-container p[data-v-71a0f9a8] {
  margin-bottom: 10px;
}
.chart-container .chart-main[data-v-71a0f9a8] {
  height: 300px;
  margin-top: 10px;
}
[data-v-71a0f9a8] #gridHeadLayout > div {
  padding: 0 !important;
}
[data-v-71a0f9a8] .avue-crud .el-table {
  height: 320px !important;
  max-height: 320px !important;
}
.yellow[data-v-71a0f9a8] {
  color: rgb(255, 116, 0);
}