.areaCheckRecord[data-v-863cc960] {
  height: 100%;
}
.areaCheckRecord .el-main[data-v-863cc960] {
  overflow: hidden;
}
[data-v-863cc960] .areaCheckRecord_container #gridLayout {
  height: calc(100% - 100px) !important;
}
[data-v-863cc960] .areaCheckRecord_container .avue-crud {
  height: 100% !important;
}
[data-v-863cc960] .areaCheckRecord_container .avue-crud .el-card {
  height: 100% !important;
  overflow: hidden;
}
[data-v-863cc960] .areaCheckRecord_container .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-863cc960] .areaCheckRecord_container .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-863cc960] .areaCheckRecord_container .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
  max-height: 100% !important;
}