.el-autocomplete[data-v-0a3c6883] {
  width: 100%;
}
.el-input-number[data-v-0a3c6883] {
  width: 90%;
}
.time-box[data-v-0a3c6883] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}