
.buttons-box {
  width: 100%;
  height: 28px;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  bottom: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 10;
}
.jessibuca-btn {
  width: 20px;
  color: rgb(255, 255, 255);
  line-height: 27px;
  margin: 0px 10px;
  padding: 0px 2px;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem !important;
}
.buttons-box-right {
  position: absolute;
  right: 0;
}
.iconfont {
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-play:before {
  content: "\E603";
}
.icon-pause:before {
  content: "\E6C6";
}
.icon-stop:before {
  content: "\E6A8";
}
.icon-audio-high:before {
  content: "\E793";
}
.icon-audio-mute:before {
  content: "\E792";
}
.icon-shuaxin11:before {
  content: "\E720";
}
.icon-weibiaoti10:before {
  content: "\E78F";
}
.icon-weibiaoti11:before {
  content: "\E790";
}
.icon-camera1196054easyiconnet:before {
  content: "\E791";
}
