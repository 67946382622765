.LuckySheet {
  overflow: hidden;
  height: 100vh;
}
.LuckySheet #luckysheet {
  height: calc(100% - 80px);
}
.LuckySheet .calcHeight {
  height: calc(100% - 82px) !important;
}
.LuckySheet .btn-position {
  margin-top: 40px;
}
.LuckySheet .el-dialog {
  max-height: 95vh;
  height: 95vh;
}
.LuckySheet .el-dialog .el-dialog__header {
  border-bottom: 0;
}
.LuckySheet .el-dialog .el-dialog__footer {
  border-top: 0;
}
.LuckySheet .el-dialog .el-dialog__body {
  padding: 0 10px !important;
  max-height: calc(95% - 80px);
  height: calc(95% - 80px);
}
.LuckySheet h3 {
  margin: 40px 0 0;
}
.LuckySheet ul {
  list-style-type: none;
  padding: 0;
}
.LuckySheet li {
  display: inline-block;
  margin: 0 10px;
}
.LuckySheet a {
  color: #42b983;
}
.luckysheet-dropCell-icon {
  display: none;
}
.luckysheet-input-box {
  z-index: 10000;
}
.luckysheet-paneswrapper {
  /*display: none;*/
}