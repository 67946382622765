.headers[data-v-945bb314] {
  background: #ffffff;
  padding: 14px 12px;
}
[data-v-945bb314] .platformCourses .avue-crud .el-table {
  height: calc(100vh - 316px) !important;
  max-height: calc(100vh - 316px) !important;
}
.headers[data-v-945bb314] .el-button {
  height: 28px;
}
.el-button + .el-button[data-v-945bb314] {
  margin-left: 5px !important;
}
[data-v-945bb314] .bjs-powered-by {
  display: none;
}
.courseCoverImg[data-v-945bb314] {
  width: 80px;
  padding: 5px;
}