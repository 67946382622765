.left-box[data-v-512dd0f3] {
  width: 324px;
}
.table-box[data-v-512dd0f3] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.gridLayOut[data-v-512dd0f3] {
  margin-top: 5px;
}
[data-v-512dd0f3] .common_tree_handle .el-tree {
  height: calc(100vh - 223px) !important;
}
[data-v-512dd0f3] .avue-crud .el-table {
  height: calc(100vh - 223px) !important;
  max-height: calc(100vh - 223px) !important;
}