.checklist .table-box {
  height: calc(100% - 307px) !important;
}
.checklist .avue-crud .el-table {
  height: calc(100vh - 581px) !important;
  max-height: calc(100vh - 581px) !important;
}
.checklist .charts-box {
  background-color: #ffffff;
  padding: 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.checklist .charts-box .charts-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 33.3333333333%;
  height: 260px;
  background: #ffffff;
  border: 1px solid rgb(204, 204, 204);
  border-top: 0;
}
.checklist .charts-box .no-border-right {
  border-right: 0;
}