.dbtMonitorAccount[data-v-4f1a24e2] {
  width: 100%;
  height: 100%;
}
.tableCentent[data-v-4f1a24e2] {
  width: 100%;
  height: calc(100% - 100px);
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
}
[data-v-4f1a24e2] .el-table--border {
  border-bottom: 1px solid #e4e4e4 !important;
}
.tableBottom[data-v-4f1a24e2] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-top: 10px;
}