[data-v-5a65ec85] .checkList_dialog .el-main {
  height: 100% !important;
}
[data-v-5a65ec85] .checkDialogGridLayOut {
  height: calc(100% - 120px) !important;
}
[data-v-5a65ec85] .checkList_dialog_tree .el-tree {
  height: calc(100% - 120px) !important;
}
[data-v-5a65ec85] .common_tree_handle {
  height: 100%;
}