@charset "UTF-8";
/* 改变el-option文本颜色 */
.custom-option.el-select-dropdown__item[data-v-729b6071] {
  color: black; /* 你想要的颜色 */
}
.el-input-number.el-input-number--small[data-v-729b6071] {
  width: 100%;
}
[data-v-729b6071] .el-tabs__content {
  background-color: transparent;
}
.main-content[data-v-729b6071] {
  widows: 100%;
  height: 100%;
}
.el-main[data-v-729b6071] {
  height: 100%;
  overflow: hidden;
}
[data-v-729b6071].inOut-content {
  height: calc(100% - 50px);
}
[data-v-729b6071].inOut-content #gridLayout {
  height: calc(100% - 112px);
}
[data-v-729b6071].inOut-content #gridLayout .avue-crud {
  height: 100%;
}
[data-v-729b6071].inOut-content #gridLayout .avue-crud .el-card {
  height: calc(100% - 46px);
}
[data-v-729b6071].inOut-content #gridLayout .avue-crud .avue-crud__pagination {
  height: 46px !important;
}
[data-v-729b6071].inOut-content #gridLayout .avue-crud .el-card__body {
  height: 100%;
}
[data-v-729b6071].inOut-content #gridLayout .avue-crud .el-card__body .el-form {
  height: 100%;
}
[data-v-729b6071].inOut-content #gridLayout .avue-crud .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-729b6071] .left {
  position: relative;
  background-color: #fff;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 100%;
  margin-right: 10px;
  height: 100%;
}
[data-v-729b6071] .left .el-card__body {
  padding: 10px !important;
  width: 100%;
  height: 100%;
}
[data-v-729b6071] .left .list {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-v-729b6071] .left .list .list-card {
  border-radius: 6px;
  margin-bottom: 10px;
  border: 1px solid transparent !important;
}
[data-v-729b6071] .left .list .list-card .name {
  text-align: center;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
}
[data-v-729b6071] .left .list .list-card .el-divider {
  width: calc(100% + 20px);
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
[data-v-729b6071] .left .list .list-card .bottom {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
}
[data-v-729b6071] .left .up-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 294px;
}
[data-v-729b6071] .left .up-card .upload {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.image-slot[data-v-729b6071] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}