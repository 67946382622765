.sortSetting .table-box[data-v-12e3734e] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.sortSetting .common_tree_handle .el-tree[data-v-12e3734e] {
  height: calc(100vh - 272px) !important;
}
[data-v-12e3734e] .el-tabs__content {
  padding: 0 !important;
}