.disFlex[data-v-10fbc98f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
}
.card-pd-2[data-v-10fbc98f] {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}
.card-pd-2-top[data-v-10fbc98f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.card-pd-2-top-Title[data-v-10fbc98f] {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}
.card-pd-2-top-btn[data-v-10fbc98f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.card-pd-2-top-btn img[data-v-10fbc98f] {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}
.card-pd-2-Bottom[data-v-10fbc98f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 21px;
}
.card-pd-2-BotLitte[data-v-10fbc98f] {
  width: calc(50% - 64px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 40px 26px;
  position: relative;
}
.groupImg[data-v-10fbc98f] {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 26px;
  right: 26px;
}
.priceNumber[data-v-10fbc98f] {
  width: 100%;
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
}
.priceNumber span[data-v-10fbc98f] {
  font-weight: 400;
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
}
.lineBox[data-v-10fbc98f] {
  width: 40px;
  height: 3px;
  background-color: #FB3636;
  margin: 21px 0 26px;
}
.priceContent[data-v-10fbc98f] {
  width: 100%;
  color: #15161A;
  font-size: 18px;
}
.bottomChartBox[data-v-10fbc98f] {
  width: calc(50% - 54px);
  border: 1px solid #EDEDED;
  border-radius: 10px;
  padding: 21px;
}
.bottomChartTitle[data-v-10fbc98f] {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
  margin-bottom: 20px;
}
#chart-fourteen[data-v-10fbc98f] {
  width: 209px;
  height: 209px;
  margin: auto;
}
#chart-sixteen[data-v-10fbc98f] {
  width: 100%;
  height: 450px;
}
#chart-seventeen[data-v-10fbc98f] {
  width: 100%;
  height: 378px;
}
#chart-fifteen[data-v-10fbc98f] {
  width: 209px;
  height: 209px;
  margin: auto;
}