.disFlex[data-v-51a97fea] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
}
.card-pd-2[data-v-51a97fea] {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}
.card-pd-2-top[data-v-51a97fea] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.card-pd-2-top-Title[data-v-51a97fea] {
  color: #15161a;
  font-size: 21px;
  font-weight: bold;
}
.card-pd-2-top-btn[data-v-51a97fea] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.card-pd-2-top-btn img[data-v-51a97fea] {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}
.card-pd-2-Bottom[data-v-51a97fea] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 21px;
}
#chart-six[data-v-51a97fea] {
  width: 209px;
  height: 209px;
  margin-left: 77px;
}
#chart-seven[data-v-51a97fea] {
  width: 70%;
  height: 248px;
}
#chart-eight[data-v-51a97fea] {
  width: 100%;
  height: 320px;
}
#chart-nine[data-v-51a97fea] {
  width: 100%;
  height: 320px;
}
.chart-six-info[data-v-51a97fea] {
  padding: 33px 0;
  margin-left: 46px;
}
.chart-six-info-box[data-v-51a97fea] {
  color: #1d2129;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.info-number[data-v-51a97fea] {
  margin: 0 12px;
  width: 50px;
}
.info-circle[data-v-51a97fea] {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin: 6px 6px 6px 0;
}
.card-pd-2-BotLitte2[data-v-51a97fea] {
  width: 30%;
  border-radius: 8px;
  padding: 21px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-32.69%, rgba(255, 225, 231, 0.3803921569)), to(#fffcfc));
  background: linear-gradient(180deg, rgba(255, 225, 231, 0.3803921569) -32.69%, #fffcfc 100%);
}
.botLitte2-number[data-v-51a97fea] {
  color: #15161a;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}
.botLitte2-number span[data-v-51a97fea] {
  color: #484a4d;
  font-size: 18px;
  margin-left: 8px;
}
.botLitte2-title[data-v-51a97fea] {
  width: 100%;
  color: #484a4d;
  font-size: 21px;
}
.botLitte2-info[data-v-51a97fea] {
  color: #15161a;
  font-size: 18px;
  margin-top: 13px;
}
.botLitte2-info span[data-v-51a97fea] {
  color: #fb3636;
}
.leftChartBox[data-v-51a97fea] {
  width: 60%;
}
.rightChartBox[data-v-51a97fea] {
  width: 40%;
}
#chart-ten[data-v-51a97fea] {
  width: 209px;
  height: 209px;
  margin: auto;
}
#chart-eleven[data-v-51a97fea] {
  width: 209px;
  height: 209px;
  margin: auto;
}
#chart-twelve[data-v-51a97fea] {
  width: 100%;
  height: 320px;
}
#chart-thirteen[data-v-51a97fea] {
  width: 100%;
  height: 320px;
}
.chart-ten-info[data-v-51a97fea] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.chart-ten-info .chart-six-info-box[data-v-51a97fea] {
  margin-top: 18px;
}
[data-v-51a97fea] .el-slider__runway.disabled .el-slider__bar {
  background-color: #fb3636;
}
[data-v-51a97fea] .el-slider__runway.disabled .el-slider__button {
  border-color: #fb3636;
}