.searchTopBox[data-v-a2668126] {
  padding: 14px 12px;
  width: calc(100% - 24px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.leftSearch[data-v-a2668126] {
  width: 90%;
}
.searchBoxcc[data-v-a2668126] {
  width: 22% !important;
  margin-right: 5px;
}
.searchBox[data-v-a2668126] {
  width: 15% !important;
  margin-right: 5px;
}