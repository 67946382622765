@charset "UTF-8";
#homeIndex[data-v-5954443c] {
  height: 100%;
}
[data-v-5954443c] .el-carousel__arrow {
  width: 24px;
  height: 24px;
}
[data-v-5954443c] .el-carousel__arrow--left {
  left: 0;
  background-color: #fbe5e3;
  display: none;
}
[data-v-5954443c] .el-carousel__arrow--left:hover {
  background-color: #e64741;
}
[data-v-5954443c] .el-carousel__arrow--right {
  right: 0;
  background-color: #fbe5e3;
  display: none;
}
[data-v-5954443c] .el-carousel__arrow--right:hover {
  background-color: #e64741;
}
.disFlex[data-v-5954443c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.cardBox6[data-v-5954443c] {
  width: calc(66.6% - 48px);
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
          box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
  padding: 16px 20px;
}
.cardBox3[data-v-5954443c] {
  width: calc(100% - 38px);
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
          box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
  padding: 16px 20px;
}
.cardBox3[data-v-5954443c] .el-tabs__content {
  height: calc(100% - 40px);
}
.cardBox3[data-v-5954443c] .el-tabs {
  height: 100%;
}
.cardBox3[data-v-5954443c] .el-tab-pane {
  height: 100%;
}
.cardBox3[data-v-5954443c] .el-table__body-wrapper {
  height: calc(100% - 44px);
  overflow-y: auto;
}
.cardTitleHome[data-v-5954443c] {
  width: 100%;
  color: #1d2129;
  font-size: 18px;
  margin-bottom: 11px;
}
.cardTitleHome .board[data-v-5954443c] {
  font-size: 16px;
  margin-left: 16px;
}
.cardTitleHome .board .el-icon-right[data-v-5954443c] {
  font-size: 16px;
}
.cardTitleHome[data-v-5954443c] .el-tabs__content {
  height: calc(100% - 40px);
}
.cardTitleHome[data-v-5954443c] .el-tabs {
  height: 100%;
}
.cardTitleHome[data-v-5954443c] .el-tab-pane {
  height: 100%;
}
.cardTitleHome[data-v-5954443c] .el-table__body-wrapper {
  height: calc(100% - 44px);
  overflow-y: auto;
}
.leftChange[data-v-5954443c] {
  width: 24px;
  height: 24px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin: 103px 0;
  cursor: pointer;
}
.rightChange[data-v-5954443c] {
  width: 24px;
  height: 24px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin: 103px 0;
  cursor: pointer;
}
.targetBox[data-v-5954443c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.targetLittle[data-v-5954443c] {
  width: 30%;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 7px;
  background: linear-gradient(125deg, rgba(235, 240, 246, 0.5803921569) 2.35%, rgba(243, 246, 250, 0.5803921569) 94.99%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.targetTitle[data-v-5954443c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #333333;
}
.iconContent[data-v-5954443c] {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
}
.disFlex-left[data-v-5954443c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 30px;
}
.income[data-v-5954443c] {
  width: 25px;
  height: 25px;
}
.number[data-v-5954443c] {
  font-size: 26px;
  font-weight: bold;
  color: #15161a;
}
.menu-box[data-v-5954443c] {
  width: calc(33.3% - 8px);
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
          box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.0588235294);
}
.menu-box .quick-app-list[data-v-5954443c] {
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.menu-box .quick-app-list .menu-title[data-v-5954443c] {
  width: 100%;
  color: #1d2129;
  font-size: 18px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #f4f5f6;
  padding-bottom: 20px;
}
.menu-box .quick-app-list .list-content[data-v-5954443c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: calc(100% - 56px);
}
.menu-box .quick-app-list .list-content .quick-app-item[data-v-5954443c] {
  width: 25%;
  text-align: center;
  font-weight: 400;
  color: #666666;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 7px;
}
.menu-box .quick-app-list .list-content .quick-app-item .imageBox[data-v-5954443c] {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
.menu-box .quick-app-list .list-content .quick-app-item .imageBox i[data-v-5954443c] {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}
.menu-box .quick-app-list .list-content .quick-app-item span[data-v-5954443c] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;
}
.unit[data-v-5954443c] {
  font-size: 13px;
  color: #484a4d;
  margin-left: 6px;
}
.homeHtBox[data-v-5954443c] {
  font-size: 14px;
  color: #484a4d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  line-height: 20px;
  margin-top: 8px;
}
.htImg[data-v-5954443c] {
  width: 14px;
  height: 14px;
  margin: 3px 0;
}
.htNumber[data-v-5954443c] {
  margin: 0 7px;
}
.morningBox[data-v-5954443c] {
  width: 100%;
  background-image: url(../../static/img/morning.75db62ba.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 17px;
  height: calc(50% - 8px);
}
.assistantBox[data-v-5954443c] {
  width: 100%;
  background-image: url(../../static/img/assistant.018a393f.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(50% - 8px);
  cursor: pointer;
}
.callBox[data-v-5954443c] {
  color: #15161a;
  font-size: 25px;
  font-weight: bold;
  padding-top: 11%;
  margin-left: 20px;
}
.dateAndWeather[data-v-5954443c] {
  color: #484a4d;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 14px;
}
.taskHomeBox[data-v-5954443c] {
  width: 200px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 6px;
  padding: 14px 0;
}
.taskHomeBox[data-v-5954443c]:hover {
  background-color: #f8fafb;
}
.taskHomeImg[data-v-5954443c] {
  width: 40px;
  height: 40px;
  margin: 7px 0;
  margin-right: 20px;
}
.taskNumber[data-v-5954443c] {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 6px;
}
.taskName[data-v-5954443c] {
  font-size: 14px;
  color: #484a4d;
}
.needTodo[data-v-5954443c] {
  line-height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.allNameBox[data-v-5954443c] {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  top: 11px;
  right: 0;
}
.todoContentBox[data-v-5954443c] {
  width: 100%;
  overflow: auto;
}
.todoContentLittle[data-v-5954443c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  margin-bottom: 6px;
}
.todoContentLittle[data-v-5954443c]:hover {
  text-decoration: underline;
}
.todoContentLittle[data-v-5954443c]:last-child {
  margin-bottom: 0;
}
.commonReport[data-v-5954443c] {
  width: 33.3%;
  text-align: center;
  font-size: 14px;
  color: #484a4d;
  cursor: pointer;
}
.commonReport span[data-v-5954443c] {
  white-space: normal;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
}
.commonReportImg[data-v-5954443c] {
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
  margin-bottom: 12px;
}
.serviceHomeBox[data-v-5954443c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.serviceLittleBox[data-v-5954443c] {
  width: 27%;
  border-radius: 4px;
  background-color: #f7f7f8;
  padding: 10px;
  color: #484a4d;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
}
.serviceLittleBox[data-v-5954443c]:nth-child(3n) {
  margin-right: 0px;
}
.servicImg[data-v-5954443c] {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
[data-v-5954443c] .el-tabs__content {
  background-color: transparent;
}
[data-v-5954443c] .el-tabs__header {
  margin: 0;
}
.moreTitle[data-v-5954443c] {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  top: 27px;
  right: 20px;
}
.newsBox[data-v-5954443c] {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.newsBox[data-v-5954443c]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.newsLittle[data-v-5954443c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 34px;
  border-bottom: 1px solid #ebeef5;
  cursor: pointer;
  color: #606266;
}
.point[data-v-5954443c] {
  padding: 5px;
}
.point[data-v-5954443c]::after {
  content: "\25CF";
  font-size: 8px;
}
.newsTitle[data-v-5954443c] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  width: 60%;
}
.newsTime[data-v-5954443c] {
  width: 40%;
  text-align: right;
  font-size: 14px;
}
.newsLittle[data-v-5954443c]:hover {
  color: #2979ff !important;
}
.noticeTitle[data-v-5954443c] {
  width: 90%;
  margin: auto;
  text-align: center;
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}
.noticeTime[data-v-5954443c] {
  width: 100%;
  text-align: left;
  color: #606266;
  font-size: 18px;
  padding-top: 36px;
  padding-bottom: 12px;
  border-bottom: 1px solid #c0c4cc;
}
.noticeContent[data-v-5954443c] {
  width: 100%;
}
[data-v-5954443c] .el-table th {
  color: rgba(0, 0, 0, 0.85);
}
[data-v-5954443c] .el-table--medium .el-table__cell {
  padding: 5px 0;
}