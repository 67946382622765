ul[data-v-37eef94e],
li[data-v-37eef94e] {
  list-style: none;
  padding: 0;
  margin: 0;
}
.safe-intelligent .video[data-v-37eef94e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.big-video[data-v-37eef94e] {
  width: 134.6511627907vh;
  height: 81.7829457364vh;
  margin-top: 1.5503875969vh;
  margin-right: 2.7131782946vh;
  background: #ccc;
  opacity: 0.8;
}
.small-video[data-v-37eef94e] {
  height: 100%;
}
.small-video .top-video[data-v-37eef94e] {
  margin-top: 1.5503875969vh;
  background-image: url(../../static/img/videoBox.fd20636a.png);
  background-size: 96.8992248062vh 54.2635658915vh;
  background-repeat: no-repeat;
  width: 96.8992248062vh;
  height: 54.2635658915vh;
}
.small-video .top-video .video-title[data-v-37eef94e] {
  padding-left: 3.488372093vh;
  line-height: 4.4186046512vh;
  color: #fff;
  font-size: 1.3953488372vh;
}
.small-video .top-video .video-list[data-v-37eef94e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.small-video .top-video .video-list li[data-v-37eef94e] {
  width: 46.511627907vh;
  height: 23.2558139535vh;
  margin-bottom: 0.7751937984vh;
  background-color: #fff;
}
.small-video .bottom-text[data-v-37eef94e] {
  margin-top: 2.3255813953vh;
  width: 96.8992248062vh;
  height: 25.1937984496vh;
  background-image: url(data:image/png;base64,);
  background-size: 96.8992248062vh 25.1937984496vh;
  background-repeat: no-repeat;
}
.small-video .bottom-text .video-title[data-v-37eef94e] {
  padding-left: 3.488372093vh;
  line-height: 4.4186046512vh;
  color: #fff;
  font-size: 1.3953488372vh;
}
.el-col[data-v-37eef94e] {
  line-height: 3.8759689922vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.el-col label[data-v-37eef94e] {
  padding-left: 0.7751937984vh;
  margin-right: 0.7751937984vh;
  min-width: 8vh;
  font-size: 1.3953488372vh;
  text-align: end;
  color: #fff;
}
.el-col span[data-v-37eef94e] {
  font-size: 1.3953488372vh;
  color: #fff;
}
.el-col > span[data-v-37eef94e] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.green-style[data-v-37eef94e] {
  color: #15db6d !important;
}