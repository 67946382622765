.table-box[data-v-b4b316aa] {
  border: 1px solid;
}
.table-box .line[data-v-b4b316aa] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.table-box .line .item[data-v-b4b316aa] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 30%;
}
.table-box .line .item .label[data-v-b4b316aa],
.table-box .line .item .value[data-v-b4b316aa] {
  border-right: 1px solid;
  border-bottom: 1px solid;
}
.table-box .line .item .required[data-v-b4b316aa] {
  position: relative;
}
.table-box .line .item .required[data-v-b4b316aa]::before {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}
.table-box .line .item .not-required[data-v-b4b316aa] {
  position: relative;
}
.table-box .line .item .not-required[data-v-b4b316aa]::before {
  content: "*";
  color: transparent;
  padding-left: 4px;
}
.table-box .line .item .value[data-v-b4b316aa] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.table-box .line .item .label[data-v-b4b316aa] {
  text-align: left;
  width: 100px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.table-box .line-2 .item .value[data-v-b4b316aa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.table-box .line-4 .item .value[data-v-b4b316aa] {
  height: 100%;
}
.table-box .line-3 .item[data-v-b4b316aa],
.table-box .line-4 .item[data-v-b4b316aa] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 50%;
}
[data-v-b4b316aa] .table-box .el-textarea__inner {
  border: none;
}
[data-v-b4b316aa] .table-box .el-input__inner {
  border: none;
}
[data-v-b4b316aa].table-box .need-border .el-input__inner {
  border: 1px solid #dcdfe6;
}
[data-v-b4b316aa] textarea.el-textarea__inner {
  height: 100%;
}