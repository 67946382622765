[data-v-771cc334] .home .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}
.homeTopTabs[data-v-771cc334] {
  width: calc(100% - 24px);
  padding: 0 12px;
  background-color: #FFF;
}
.homeTopTabs[data-v-771cc334] .el-tabs__header {
  margin: 0;
}