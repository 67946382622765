.tabsBox[data-v-0bba573c] {
  background-color: #ffffff;
  padding: 12px;
}
[data-v-0bba573c] .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
[data-v-0bba573c] .el-tabs__header {
  margin: 0;
}
[data-v-0bba573c] .el-tabs__content {
  padding: 0 !important;
  background-color: transparent;
}