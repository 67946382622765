.main-container[data-v-4a9d3732] {
  width: 100%;
  height: 100%;
}
.main-container .el-main[data-v-4a9d3732] {
  height: 100%;
}
[data-v-4a9d3732] .el-container {
  width: 100%;
  height: 100%;
}
.equimpentStatistics .equimpentStatistics-card[data-v-4a9d3732] {
  padding: 12px;
  height: 100px;
}
.equimpentStatistics .equimpentStatistics-card[data-v-4a9d3732] .el-row {
  height: 100%;
}
.equimpentStatistics .equimpentStatistics-card[data-v-4a9d3732] .el-col {
  height: 100%;
}
.equimpentStatistics .equimpentStatistics-card .card-item[data-v-4a9d3732] {
  background-color: #1990ff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  color: #fff;
  position: relative;
}
.equimpentStatistics .equimpentStatistics-card .card-item .card-item-title[data-v-4a9d3732] {
  font-size: 14px;
}
.equimpentStatistics .equimpentStatistics-card .card-item .card-item-num[data-v-4a9d3732] {
  font-size: 24px;
}
.equimpentStatistics .equimpentStatistics-card .card-item i[data-v-4a9d3732] {
  position: absolute;
  left: 5px;
  top: 5px;
}
.equimpentInventory[data-v-4a9d3732] {
  height: calc(100% - 172px);
  overflow: hidden;
}
[data-v-4a9d3732] #gridLayout {
  height: calc(100% - 138px) !important;
}
[data-v-4a9d3732] .avue-crud {
  height: 100% !important;
}
[data-v-4a9d3732] .avue-crud .el-card {
  height: 100% !important;
}
[data-v-4a9d3732] .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-4a9d3732] .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-4a9d3732] .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
}
.eq-status-box[data-v-4a9d3732] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}
.eq-status[data-v-4a9d3732] {
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.eq-status-1[data-v-4a9d3732] {
  border: 1px solid #67c23a;
  color: #67c23a;
  background-color: #EEF8E8;
}
.eq-status-2[data-v-4a9d3732] {
  border: 1px solid #606266;
  color: #606266;
  background-color: #F2F2F3;
}
.eq-status-3[data-v-4a9d3732] {
  border: 1px solid #E49D3F;
  color: #E49D3F;
  background-color: #FDF5E9;
}
.eq-status-4[data-v-4a9d3732] {
  border: 1px solid #F69090;
  color: #F69090;
  background-color: #FEEEEE;
}
.overDue[data-v-4a9d3732] {
  color: #C5211B;
}