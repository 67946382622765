.checkTasks .calcWidth[data-v-36e6348c] {
  width: calc(100% - 280px);
}
.checkTasks .first[data-v-36e6348c] {
  width: 100%;
}
.checkTasks .checkTasks-box[data-v-36e6348c] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.checkTasks .checkTasks-box .charts-icon[data-v-36e6348c] {
  width: calc(12.5% - 20px);
  height: 100px;
  background: #ffffff;
  border: 1px solid rgb(204, 204, 204);
  border-top: 0;
}
.checkTasks .checkTasks-box .no-border-right[data-v-36e6348c] {
  border-right: 0;
}
[data-v-36e6348c] .common_tree_handle .el-tree {
  height: calc(100vh - 253px) !important;
}
[data-v-36e6348c] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
[data-v-36e6348c] .el-tabs__content {
  padding: 0 !important;
}