.abortive-container[data-v-65998276] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.abortive-container .right-main[data-v-65998276] {
  width: calc(100% - 280px);
}
[data-v-65998276] .avue-crud .el-table {
  height: calc(100vh - 272px) !important;
  max-height: calc(100vh - 272px) !important;
}
.treeHeader[data-v-65998276] {
  margin: 14px 12px;
  width: calc(100% - 24px) !important;
}