[data-v-57207ee2] .el-dialog__body {
  padding: 40px 30px !important;
}
.grid-content[data-v-57207ee2] {
  text-align: center;
}
.grid-content[data-v-57207ee2]:first-child {
  border-right: 1px solid #dcdfe6;
}
.grid-content p[data-v-57207ee2] {
  text-align: center;
}
.grid-content img[data-v-57207ee2] {
  width: 128px;
  margin: 10px 0;
}