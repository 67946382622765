.search-form[data-v-17074295] {
  background: #FFFFFF;
  padding: 14px 12px;
}
.search-form[data-v-17074295] .el-form-item--small .el-form-item__content {
  line-height: 28px !important;
}
.search-form[data-v-17074295] .el-button {
  height: 28px;
  margin-left: 0;
}
.search-form[data-v-17074295] .el-input__inner {
  height: 28px;
  line-height: 28px;
}
