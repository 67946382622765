.videoMain[data-v-544dd060] {
  width: 100%;
  height: 100%;
}
.videoTopBox[data-v-544dd060] {
  width: calc(100% - 12px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #ffffff;
  padding: 6px 6px 0 6px;
}
.videoTopLeft[data-v-544dd060] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.videoBoardBox[data-v-544dd060] {
  width: 100%;
  padding: 6px;
}
.videoBoard[data-v-544dd060] {
  width: calc(100% - 12px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-size: 18px;
  padding: 0 6px;
}
.videoBoard[data-v-544dd060]:hover {
  background-color: #F2F6FC;
}
.videoBig[data-v-544dd060] {
  width: calc(100% - 12px);
  height: calc(100% - 52px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  padding: 6px;
}
.videoLeft[data-v-544dd060] {
  width: 280px;
  border-right: 1px solid rgb(204, 204, 204);
}
.leftTopGrid[data-v-544dd060] {
  width: calc(100% - 18px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #f7f7f7;
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 6px;
}
.leftTopGrid .el-button[data-v-544dd060] {
  font-size: 20px;
  padding: 4px;
}
.leftListBox[data-v-544dd060] {
  width: calc(100% - 20px);
  height: calc(100% - 64px);
  overflow: auto;
  padding: 6px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}
.el-checkbox[data-v-544dd060] {
  width: 100%;
  margin: 0 0 8px 0 !important;
}
.el-radio-group[data-v-544dd060] {
  width: 100%;
}
.el-radio[data-v-544dd060] {
  width: 100%;
  margin: 0 0 8px 0 !important;
}
.videoRight[data-v-544dd060] {
  width: calc(100% - 281px);
}
.videoMainBox[data-v-544dd060] {
  width: calc(100% - 20px);
  height: calc(100% - 14px);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 6px;
  margin-left: 6px;
}
.videoMainFullBox[data-v-544dd060] {
  width: calc(100vw - 12px);
  height: calc(100vh - 14px);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 6px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #FFFFFF;
}
.Video[data-v-544dd060] {
  width: 100%;
  height: 100%;
}
.videoBox[data-v-544dd060] {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.oneVideo[data-v-544dd060] {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #333333;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.fourVideo[data-v-544dd060] {
  width: calc(50% - 13px);
  height: calc(50% - 14px);
  border-radius: 4px;
  background-color: #333333;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.nineVideo[data-v-544dd060] {
  width: calc(33.3% - 13px);
  height: calc(33.3% - 14px);
  border-radius: 4px;
  background-color: #333333;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.twelveVideo[data-v-544dd060] {
  width: calc(33.3% - 13px);
  height: calc(25% - 14px);
  border-radius: 4px;
  background-color: #333333;
  padding: 6px;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.project-area-tree[data-v-544dd060] {
  width: 200px;
  margin-right: 12px;
}
.top-div[data-v-544dd060] {
  background-color: #fff;
  margin-left: 5px;
  padding-top: 10px;
}