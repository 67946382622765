[data-v-cc6d8850].el-container {
  height: 100%;
  overflow: hidden;
}
[data-v-cc6d8850].el-container .el-main {
  height: 100%;
  overflow: hidden;
}
[data-v-cc6d8850].el-container .el-main #gridLayout {
  height: calc(100% - 92px);
  margin-top: 10px;
}
[data-v-cc6d8850].el-container .el-main #gridLayout .avue-crud {
  height: 100%;
}
[data-v-cc6d8850].el-container .el-main #gridLayout .avue-crud .el-card {
  height: 100%;
}
[data-v-cc6d8850].el-container .el-main #gridLayout .avue-crud .el-card__body {
  height: 100%;
}
[data-v-cc6d8850].el-container .el-main #gridLayout .avue-crud .el-card__body .el-form {
  height: 100%;
}
[data-v-cc6d8850].el-container .el-main #gridLayout .avue-crud .el-card__body .el-form .el-table {
  height: 100% !important;
  max-height: none !important;
}
.right-10[data-v-cc6d8850] {
  margin-right: 10px;
}
.bottom-10[data-v-cc6d8850] {
  margin-bottom: 10px;
}
.main-content[data-v-cc6d8850] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.main-content .table-box[data-v-cc6d8850] {
  width: calc(50% - 5px);
  height: calc(50% - 5px);
  background-color: white;
}