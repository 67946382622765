.videoTopSet[data-v-79f9621f] {
  width: calc(100% - 6px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}
.videoNumber[data-v-79f9621f] {
  width: 70px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #333333;
  background-color: #FFFFFF;
  border: 1px solid #333333;
  border-radius: 5px;
  margin-right: 12px;
  font-size: 12px;
}
.videoOne[data-v-79f9621f] {
  width: calc(100% - 6px);
  padding: 6px;
  background-color: #FFFFFF;
  height: calc(100% - 60px);
}
.video-container[data-v-79f9621f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: calc(100% - 60px);
}
.video-container-six[data-v-79f9621f] {
  width: 100%;
  height: calc(100% - 48px);
}
.video-wrapper[data-v-79f9621f] {
  width: calc(50% - 12px);
  height: calc(50% - 6px);
  padding: 6px;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}
.loadingGif[data-v-79f9621f] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-player-two[data-v-79f9621f] {
  width: 100%;
  height: 100%;
}
.video-player[data-v-79f9621f] {
  width: 100%;
  height: 100%;
}
.topVideo[data-v-79f9621f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 66.6%;
}
.bottomVideo[data-v-79f9621f] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 33.3%;
}
.twoVideo[data-v-79f9621f] {
  position: relative;
  width: calc(100% - 12px);
  height: calc(50% - 12px);
  padding: 6px;
  background-color: #FFFFFF;
}
.threeVideo[data-v-79f9621f] {
  position: relative;
  width: calc(33.3% - 12px);
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #FFFFFF;
}
.bigVideo[data-v-79f9621f] {
  position: relative;
  width: calc(66.6% - 12px);
  height: calc(100% - 12px);
  padding: 6px;
  background-color: #FFFFFF;
}