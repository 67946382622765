.disFlex[data-v-b161ef50] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 16px;
}
.card-pd-2[data-v-b161ef50] {
  width: calc(50% - 60px);
  padding: 21px 26px;
  background-color: #FFFFFF;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
}
.card-pd-2-top[data-v-b161ef50] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.card-pd-2-top-Title[data-v-b161ef50] {
  color: #15161A;
  font-size: 21px;
  font-weight: bold;
}
.card-pd-2-top-btn[data-v-b161ef50] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.card-pd-2-top-btn img[data-v-b161ef50] {
  width: 21px;
  height: 21px;
  margin-left: 10px;
  cursor: pointer;
}
.card-pd-2-Bottom[data-v-b161ef50] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 21px;
}
.card-pd-2-BotLitte2[data-v-b161ef50] {
  width: calc(33.3% - 52px);
  border-radius: 8px;
  padding: 21px;
  border: 1px solid #EDEDED;
}
.botLitte2-number[data-v-b161ef50] {
  color: #15161A;
  font-size: 37px;
  font-weight: bold;
  margin-top: 21px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}
.botLitte2-number span[data-v-b161ef50] {
  color: #484A4D;
  font-size: 18px;
  margin-left: 8px;
  font-weight: 400;
}
.botLitte2-title[data-v-b161ef50] {
  width: 100%;
  color: #484A4D;
  font-size: 21px;
}
.botLitte2-info[data-v-b161ef50] {
  color: #15161A;
  font-size: 18px;
  margin-top: 13px;
}
.botLitte2-info span[data-v-b161ef50] {
  color: #FB3636;
}
#chart-eighteen[data-v-b161ef50] {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}
#chart-nineteen[data-v-b161ef50] {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}
#chart-twenty[data-v-b161ef50] {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 21px;
}
#chart-twenty-one[data-v-b161ef50] {
  width: 100%;
  height: 420px;
}
#chart-twenty-two[data-v-b161ef50] {
  width: 100%;
  height: 438px;
}
[data-v-b161ef50] .el-slider__runway.disabled .el-slider__bar {
  background-color: #FB3636;
}
[data-v-b161ef50] .el-slider__runway.disabled .el-slider__button {
  border-color: #FB3636;
}