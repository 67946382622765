.enableFlag-box[data-v-6ca16347] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}
.enableFlag-box .enableFlag-Y[data-v-6ca16347] {
  border: 1px solid #67c23a;
  color: #67c23a;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.enableFlag-box .enableFlag-N[data-v-6ca16347] {
  border: 1px solid #606266;
  color: #606266;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.inspection_plan-index[data-v-6ca16347] {
  height: 100% !important;
  overflow: hidden;
}
[data-v-6ca16347] #gridLayout {
  height: calc(100% - 60px) !important;
}
[data-v-6ca16347] .avue-crud {
  height: 100% !important;
}
[data-v-6ca16347] .avue-crud .el-card {
  height: 100% !important;
}
[data-v-6ca16347] .avue-crud .el-card .el-card__body {
  height: 100% !important;
}
[data-v-6ca16347] .avue-crud .el-card .el-card__body .el-form {
  height: 100% !important;
}
[data-v-6ca16347] .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
}