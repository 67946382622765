@charset "UTF-8";
.data-fill-container[data-v-7934177a] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0 10px !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
.data-fill-container .data-fill-left[data-v-7934177a] {
  height: 100%;
  background-color: #fff;
  width: 300px;
  border-radius: 8px;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: 10px;
}
.data-fill-container .data-fill-right[data-v-7934177a] {
  height: 100%;
  width: calc(100% - 310px);
  background-color: #fff;
  padding: 13px 18px 0 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.data-fill-container .data-fill-right .right-tag-header[data-v-7934177a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1[data-v-7934177a] {
  background: url(../../static/img/leftbgi.a6924b57.png) no-repeat;
  background-size: 100% 100%;
}
.data-fill-container .data-fill-right .right-tag-header .top-card2[data-v-7934177a] {
  background: url(../../static/img/middlebgi.c38360fc.png) no-repeat;
  background-size: 100% 100%;
}
.data-fill-container .data-fill-right .right-tag-header .top-card3[data-v-7934177a] {
  background: url(../../static/img/rightbgi.8156774d.png) no-repeat;
  background-size: 100% 100%;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card2[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card3[data-v-7934177a] {
  padding: 28px 21px 27px 30px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 32%;
  height: 113px;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1 .disp-flex[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card2 .disp-flex[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card3 .disp-flex[data-v-7934177a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 2px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1 .disp-flex .big-label[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card2 .disp-flex .big-label[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card3 .disp-flex .big-label[data-v-7934177a] {
  color: #202020;
  font-size: 16px;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1 .disp-flex .big-number[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card2 .disp-flex .big-number[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card3 .disp-flex .big-number[data-v-7934177a] {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1 .disp-flex .small-label[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card2 .disp-flex .small-label[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card3 .disp-flex .small-label[data-v-7934177a] {
  font-size: 14px;
  color: #666666;
}
.data-fill-container .data-fill-right .right-tag-header .top-card1 .disp-flex .small-number[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card2 .disp-flex .small-number[data-v-7934177a],
.data-fill-container .data-fill-right .right-tag-header .top-card3 .disp-flex .small-number[data-v-7934177a] {
  font-weight: 500;
  color: #202020;
  font-size: 14px;
}
.data-fill-container .data-fill-right .padd14px[data-v-7934177a] {
  padding: 10px 0 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.data-fill-container .data-fill-right .select-label-style[data-v-7934177a] {
  margin-right: 10px;
  font-size: 14px;
  color: #202020;
}
.data-fill-container .data-fill-right .Report-box[data-v-7934177a] {
  margin-top: 14px;
}
.data-fill-container .data-fill-right .Report-box .Report[data-v-7934177a] {
  cursor: pointer;
  width: calc(16.6666666667% - 15px);
  height: 50px;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 4px;
}
.data-fill-container .data-fill-right .Report-box .ReportActive[data-v-7934177a] {
  background: #ffffff;
}
.data-fill-container .data-fill-right .table-wrap-style[data-v-7934177a] {
  height: calc(100% - 285px);
}
.el-button--text[data-v-7934177a] {
  color: #3358e3;
}
.flex-column[data-v-7934177a] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.over-hidden[data-v-7934177a] {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.status-search-tabs-container[data-v-7934177a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.status-search-tabs-container .status-search-tabs-item[data-v-7934177a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  padding: 6px 12px;
  border: 1px solid #EBEEF5;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px !important;
  color: #202020;
}
.status-search-tabs-container .status-search-tabs-item.active[data-v-7934177a] {
  color: #FD5454;
  border-color: #FD5454;
}
.status-search-tabs-container .margin-r[data-v-7934177a] {
  margin-right: 10px;
}
.status-search-tabs-container .el-select[data-v-7934177a] {
  width: auto;
}
.flex-jus-sb[data-v-7934177a] {
  /*子项目在主轴方向上 均匀分布 首尾子项到父容器的距离为0*/
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* flex布局方案begin */
.flex-container[data-v-7934177a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}