p[data-v-57da0d6a] {
  margin: 0;
  padding: 0;
}
.one-job-title[data-v-57da0d6a] {
  font-size: 20px;
  margin-bottom: 10px;
}
.one-job-bottom[data-v-57da0d6a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.one-job-bottom .item-container[data-v-57da0d6a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
}
.one-job-bottom .item-container + .item-container[data-v-57da0d6a] {
  margin-left: 16px;
}
.inspection-container[data-v-57da0d6a], .one-job-bottom[data-v-57da0d6a] {
  height: calc((100% - 167px) / 2);
}
.item-container[data-v-57da0d6a] {
  background-color: #FFFFFF;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  margin-bottom: 16px;
  padding: 26px;
}
.item-container .chart-title[data-v-57da0d6a] {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}
.item-container .chart-title span[data-v-57da0d6a] {
  float: right;
}
.item-container .chart-title span i[data-v-57da0d6a] {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
.item-container .chart-container[data-v-57da0d6a] {
  height: calc(100% - 85px);
  margin-top: 10px;
}
.green[data-v-57da0d6a] {
  color: rgb(0, 211, 73);
}
.yellow[data-v-57da0d6a] {
  color: rgb(255, 116, 0);
}
.red[data-v-57da0d6a] {
  color: rgb(255, 0, 0);
}
.el-select[data-v-57da0d6a] {
  width: 200px !important;
}
.deptTitle[data-v-57da0d6a] {
  margin-left: 5px;
}