.parent_height[data-v-cc5fd92e] {
  height: 100%;
}
[data-v-cc5fd92e] .el-container {
  height: 100%;
}
[data-v-cc5fd92e] .el-upload {
  text-align: left;
}
.upload-text[data-v-cc5fd92e] {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
}
.treeHeader[data-v-cc5fd92e] {
  margin: 14px 12px;
  width: calc(100% - 24px) !important;
}
.tree-disabled[data-v-cc5fd92e] {
  color: #606060;
}