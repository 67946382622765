.content[data-v-68854499] {
  position: relative;
}
.file-name[data-v-68854499] {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
[data-v-68854499] .el-tabs__content {
  padding: 0 !important;
}
[data-v-68854499] .el-tabs__header {
  margin-bottom: 0;
}
[data-v-68854499] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}