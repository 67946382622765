.board[data-v-3175d877] {
  width: 100%;
}
.borderTabs[data-v-3175d877] {
  width: calc(100% - 68px);
  background-color: #FFFFFF;
  padding: 25px 34px 0 34px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
          box-shadow: 0px 0px 6px rgba(5, 42, 132, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tabsTitle[data-v-3175d877] {
  color: #1D2129;
  font-size: 21px;
  font-weight: bold;
  margin-right: 80px;
  text-align: center;
  cursor: pointer;
}
.tabsBottom[data-v-3175d877] {
  width: 50px;
  height: 4px;
  background-color: transparent;
  margin: 21px auto 0;
}