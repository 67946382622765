@charset "UTF-8";
.disabled[data-v-fe063c5e] {
  pointer-events: none;
  /*禁用当前元素的所有鼠标事件*/
}
.form-row[data-v-fe063c5e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  margin-bottom: 5px;
}
.ml-50[data-v-fe063c5e] {
  margin-left: 50px;
}
.div-border[data-v-fe063c5e] {
  border: 1px solid #cccccc;
  margin: 10px;
}
.btnWidth[data-v-fe063c5e] {
  width: 55px;
  margin-left: 5px;
}
.inputWidth[data-v-fe063c5e] {
  width: 100px;
  /* margin-right: 3px; */
}
.roiDrawLayout[data-v-fe063c5e] {
  height: 450px;
  float: left;
}
.floatLayout[data-v-fe063c5e] {
  float: left;
}
.img-style[data-v-fe063c5e] {
  width: 500px;
  height: 350px;
}
#myCanvas[data-v-fe063c5e] {
  position: relative;
  top: -355px;
  left: -1px;
}
.my-image-ctr[data-v-fe063c5e] {
  height: 350px;
  width: 500px;
}