.head-layout[data-v-8cda03ea] {
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  height: 46px;
}
.headLabel[data-v-8cda03ea] {
  font-size: 14px;
  margin-left: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.headBtn[data-v-8cda03ea] {
  margin-right: 6px;
}
.searchFormRow[data-v-8cda03ea] {
  margin-left: -2.5px;
  margin-right: -2.5px;
}
.searchFormRowitem[data-v-8cda03ea] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}