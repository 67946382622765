@charset "UTF-8";
.main-content[data-v-33282dbf] {
  background-color: #fff;
  widows: 100%;
  height: 100%;
}
.clacWidth[data-v-33282dbf] {
  width: calc(100% - 280px);
}
.list-empty[data-v-33282dbf] {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 164px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-33282dbf].list-card .el-card__body {
  padding: 10px !important;
}
.list[data-v-33282dbf] {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 164px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.list .list-card[data-v-33282dbf] {
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 10px;
  border: 1px solid transparent !important;
}
.list .list-card .name[data-v-33282dbf] {
  text-align: center;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
}
.list .list-card .el-divider[data-v-33282dbf] {
  width: calc(100% + 20px);
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
.list .list-card .bottom[data-v-33282dbf] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.list .list-card .bottom .btn[data-v-33282dbf] {
  margin-left: 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}