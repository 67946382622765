
[data-v-19476543] .el-textarea__inner {
  height: 70vh;
}
.dialog-footer[data-v-19476543] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

