
@-webkit-keyframes ani {
0% {
    -webkit-transform: translatex(0);
            transform: translatex(0);
}
100% {
    -webkit-transform: translatex(-100%);
            transform: translatex(-100%);
}
}
@keyframes ani {
0% {
    -webkit-transform: translatex(0);
            transform: translatex(0);
}
100% {
    -webkit-transform: translatex(-100%);
            transform: translatex(-100%);
}
}
