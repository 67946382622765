.qrcode[data-v-80d39f06] {
  width: 100%;
  text-align: center;
}
[data-v-80d39f06] .el-tabs__nav-scroll {
  width: 100% !important;
}
.el-container[data-v-80d39f06] {
  height: 100%;
}
.area_tabs[data-v-80d39f06] {
  height: calc(100% - 320px);
}
[data-v-80d39f06] .el-tabs {
  height: 100%;
}
[data-v-80d39f06] .el-tabs__content {
  height: calc(100% - 70px) !important;
}
[data-v-80d39f06] .el-tabs__content .el-tab-pane,[data-v-80d39f06] .el-tabs__content .el-container {
  height: 100%;
}
[data-v-80d39f06] .el-tabs__nav-scroll {
  padding-left: 12px !important;
}