.headers[data-v-51d4e7f6] {
  background: #ffffff;
  padding: 14px 12px;
}
.main-content[data-v-51d4e7f6] {
  width: 100%;
  height: 100%;
}
.headers[data-v-51d4e7f6] .el-button {
  height: 28px;
}
[data-v-51d4e7f6] .bjs-powered-by {
  display: none;
}
[data-v-51d4e7f6] .el-range-editor--small.el-input__inner {
  width: 100%;
}
[data-v-51d4e7f6] .el-input-number.el-input-number--small {
  width: 100%;
}