.cardLayout[data-v-0dd63391] {
  width: calc(100% - 24px);
  padding: 0 12px;
}
.cardListBig[data-v-0dd63391] {
  width: 100%;
  height: calc(100vh - 278px);
  border: 1px solid #CCCCCC;
  margin-bottom: 12px;
  overflow: auto;
}
.cardList[data-v-0dd63391] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.cardPagination[data-v-0dd63391] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.cardBox[data-v-0dd63391] {
  width: calc(25% - 26px);
  border: 1px solid #CCCCCC;
  padding: 6px;
  border-radius: 5px;
  margin: 6px;
}
.cardImg[data-v-0dd63391] {
  width: 100%;
  height: 180px;
}
.cardTitle[data-v-0dd63391] {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin: 6px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 6px;
  cursor: pointer;
}
.cardTitle[data-v-0dd63391]:hover {
  text-decoration: underline;
}
.cardTime[data-v-0dd63391] {
  width: 100%;
  font-size: 12px;
  color: #333333;
  text-indent: 6px;
}
.cardBtn[data-v-0dd63391] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 6px;
}