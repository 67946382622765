.content-formFillingPlan[data-v-4ad854b5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
  height: 100%;
}
.content-formFillingPlan .left-content[data-v-4ad854b5] {
  width: 300px;
  background-color: #fff;
  padding-right: 10px;
  border-right: 1px solid #eee;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 14px;
}
.content-formFillingPlan .left-content .tree-content[data-v-4ad854b5] {
  overflow-x: hidden;
  height: calc(100% - 110px);
}
.content-formFillingPlan .left-content .left-content-btn[data-v-4ad854b5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.content-formFillingPlan .left-content .left-content-btn label[data-v-4ad854b5] {
  font-size: 18px;
}
.content-formFillingPlan .left-content .left-content-btn .el-button[data-v-4ad854b5] {
  font-size: 14px;
}
.content-formFillingPlan .left-content .tree-filter-input[data-v-4ad854b5] {
  margin-bottom: 16px;
}
.content-formFillingPlan .left-content[data-v-4ad854b5] .el-tree-node__expand-icon {
  padding: 0;
}
.content-formFillingPlan .left-content[data-v-4ad854b5] .el-tree-node__content {
  height: 34px;
  line-height: 34px;
  margin-bottom: 8px;
}
.content-formFillingPlan .left-content[data-v-4ad854b5] .el-tree-node__content:hover {
  background-color: #fff8f8;
}
.content-formFillingPlan .left-content[data-v-4ad854b5] .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #fff8f8;
  color: #fd5454;
}
.content-formFillingPlan .left-content .el-tree-node__label[data-v-4ad854b5] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content-formFillingPlan .left-content .el-tree-node__label:hover .show-btn[data-v-4ad854b5] {
  opacity: 1;
}
.content-formFillingPlan .left-content .show-label[data-v-4ad854b5] {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-formFillingPlan .left-content .show-btn[data-v-4ad854b5] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.content-formFillingPlan .left-content .left-tree-btn[data-v-4ad854b5] {
  color: #fd5454;
}
.content-formFillingPlan .left-content .left-tree-btn i[data-v-4ad854b5] {
  font-size: 16px;
}
.content-formFillingPlan .right-content[data-v-4ad854b5] {
  height: 100%;
  width: calc(100% - 301px);
  background-color: #fff;
  border-radius: 8px;
  padding: 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.mr-20[data-v-4ad854b5] {
  margin-right: 20px;
}
.right-content-top[data-v-4ad854b5] {
  margin: 14px 0;
}
.temporary-table-Wrap[data-v-4ad854b5] {
  margin-top: 10px;
  height: calc(100% - 66px);
}
.temporary-table-Wrap .el-button--text[data-v-4ad854b5] {
  color: #3358e3;
}
.title-text[data-v-4ad854b5] {
  font-size: 18px;
  font-weight: bold;
  margin: 0 12px;
}
.status-span1[data-v-4ad854b5]::before {
  display: inline-block;
  content: "";
  background-color: #24bb4b;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.status-span0[data-v-4ad854b5]::before {
  display: inline-block;
  content: "";
  background-color: #fd5454;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.c-tabs[data-v-4ad854b5] {
  -ms-flex-line-pack: center;
      align-content: center;
}
.Reported-box[data-v-4ad854b5] {
  margin: 10px 0 10px 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
}
.left-tree-input[data-v-4ad854b5] {
  margin-bottom: 14px;
}
[data-v-4ad854b5] .el-table--border {
  border-bottom: 1px solid #e4e4e4 !important;
}