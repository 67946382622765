
[data-v-5c447c66] .el-button+.el-button {
  margin-left: 0px;
}
.btn[data-v-5c447c66]{
    margin-right: 20px;
}
.box[data-v-5c447c66]{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;

    -ms-flex-wrap: wrap;

        flex-wrap: wrap;
}
.box-item[data-v-5c447c66]{
    width: 20%;
    padding: 0px 8px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    margin-bottom: 10px;
}
.box-item-content[data-v-5c447c66]{
   min-height:200px;
}
.text[data-v-5c447c66] {
    font-size: 14px;
}
.item[data-v-5c447c66] {
    margin-bottom: 18px;
}
.clearfix[data-v-5c447c66]:before,
  .clearfix[data-v-5c447c66]:after {
    display: table;
    content: "";
}
.clearfix[data-v-5c447c66]:after {
    clear: both
}
.box-card[data-v-5c447c66] {
    width: 100%;
    /* box-shadow:10px 20px 30px 30px #ccc */
}
.new-sino-main .el-card.is-always-shadow[data-v-5c447c66]{
    border:1px solid #f0f0f0!important;
}
.form-item[data-v-5c447c66]{
    width: 200px;
    margin-right: 10px;
}
.border-box[data-v-5c447c66]{
    border:1px solid #f0f0f0!important;
    padding:0px 20px!important;
    background-color: #fff;
}
