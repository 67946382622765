
.deptForm[data-v-48da7d76] .el-form-item__label{
  width: 50px !important;
}
.deptForm[data-v-48da7d76] .el-form-item__content{
  margin-left: 50px !important;
}
.roleTable[data-v-48da7d76] .el-table{
  max-height: 380px;
}
.roleTable[data-v-48da7d76] .el-table__body-wrapper{
  max-height: 280px;
  overflow-y: auto;
}
