.left-box[data-v-4f1a0dd6] {
  width: 324px;
}
.table-box[data-v-4f1a0dd6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.introduceTree[data-v-4f1a0dd6] .common_tree_handle .el-tree {
  height: calc(100vh - 240px) !important;
}
.equipment[data-v-4f1a0dd6] {
  padding-top: 15px;
  background-color: #FFFFFF;
}
.equipment[data-v-4f1a0dd6] .avue-crud .el-table {
  height: calc(100vh - 250px) !important;
  max-height: calc(100vh - 250px) !important;
}