[data-v-b41c6506] .el-select,[data-v-b41c6506] .el-date-editor {
  width: 100%;
}
[data-v-b41c6506] .el-tabs__header {
  margin-bottom: 0;
}
.box[data-v-b41c6506] {
  position: relative;
}
.add-btn[data-v-b41c6506] {
  position: absolute;
  right: 10px;
  top: 2px;
  z-index: 100;
}
[data-v-b41c6506] .el-tabs .el-table .cell {
  padding: 0 5px;
}
[data-v-b41c6506] .el-tabs .el-table .el-input-number {
  width: 100%;
}
[data-v-b41c6506] .el-tabs .el-table .el-input-number .el-input-number__decrease,[data-v-b41c6506] .el-tabs .el-table .el-input-number .el-input-number__increase {
  display: none;
}
[data-v-b41c6506] .el-tabs .el-table .el-input-number .el-input__inner {
  padding: 0 15px;
  text-align: left;
}
.order-form[data-v-b41c6506] {
  height: 100%;
  overflow: hidden;
}
.text[data-v-b41c6506] {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
[data-v-b41c6506] .el-upload-list__item:first-child {
  margin-top: 4px;
}