.managementView .searchTopBox {
  padding: 14px 12px;
  width: calc(100% - 24px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.managementView .avue-crud .el-table {
  height: calc(100vh - 314px) !important;
  max-height: calc(100vh - 314px) !important;
}
.managementView .leftSearch {
  width: 70%;
}
.managementView .searchBox {
  width: 25% !important;
  margin-right: 5px;
}