.device-box[data-v-ea43eeca] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: scroll;
}
.device-box .device-list[data-v-ea43eeca] {
  width: 55%;
  min-width: 770px;
}
.device-box .inspection[data-v-ea43eeca] {
  width: calc(45% - 15px);
  max-width: calc(100% - 750px);
}
.device-box .inspection .inspection_plan[data-v-ea43eeca], .device-box .inspection .inspection_records[data-v-ea43eeca] {
  height: calc(50% - 8px);
  overflow-y: auto;
}
.device-box .inspection .inspection_plan[data-v-ea43eeca] {
  margin-bottom: 15px;
}
.equipment-table[data-v-ea43eeca] {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  width: 100%;
}
.equipment-table-data[data-v-ea43eeca] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.equipment-item[data-v-ea43eeca] {
  display: inline-block;
  width: calc(50% - 20px);
  height: 185px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 6px;
}
.equipment-item .equipment-item-info[data-v-ea43eeca] {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
}
.equipment-item .equipment-item-code[data-v-ea43eeca] {
  font-size: 14px;
}
.equipment-item .equipment-item-name[data-v-ea43eeca] {
  padding: 10px;
}
.equipment-item-radio[data-v-ea43eeca] {
  border: 1px solid #ccc;
}
.pagination[data-v-ea43eeca] {
  text-align: right;
  padding-right: 12px;
}
[data-v-ea43eeca] .common_tree_handle .el-tree {
  height: calc(100vh - 230px) !important;
}