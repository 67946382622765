.qu-content div[data-v-13c134ca] {
  line-height: 30px;
}
.el-checkbox-group label[data-v-13c134ca], .el-radio-group label[data-v-13c134ca] {
  width: calc(100% - 30px);
}
.el-checkbox + .el-checkbox[data-v-13c134ca] {
  margin-left: 0px;
}
.el-button + .el-button[data-v-13c134ca] {
  margin-left: 12px;
}
.card-title[data-v-13c134ca] {
  background: #eee;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
}
.card-line[data-v-13c134ca] {
  padding-left: 10px;
}
.card-line span[data-v-13c134ca] {
  cursor: pointer;
  margin: 2px;
}
.el-radio[data-v-13c134ca], .el-checkbox[data-v-13c134ca] {
  padding: 9px 20px 9px 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;
}
.is-checked[data-v-13c134ca] {
  border: #409eff 1px solid;
}
.el-radio img[data-v-13c134ca], .el-checkbox img[data-v-13c134ca] {
  max-width: 200px;
  max-height: 200px;
  border: #dcdfe6 1px dotted;
}
.el-checkbox__inner[data-v-13c134ca] {
  display: none;
}
.el-radio__inner[data-v-13c134ca] {
  display: none;
}
.el-checkbox__label[data-v-13c134ca] {
  line-height: 30px;
}
.el-radio__label[data-v-13c134ca] {
  line-height: 30px;
}
.determineImg[data-v-13c134ca] {
  position: absolute;
  right: 15%;
  top: 40px;
  width: 170px;
}
[data-v-13c134ca] .is-checked {
  border-color: #dcdfe6;
}