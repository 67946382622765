.coding-dialog[data-v-10f50111] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.coding-qrcode[data-v-10f50111] {
  text-align: center;
}
.coding-content[data-v-10f50111] {
  width: 300px;
  margin: 0 auto;
}
.column-qrcode[data-v-10f50111] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5px;
}