@charset "UTF-8";
.disabled[data-v-93a07dda] {
  pointer-events: none;
  /*禁用当前元素的所有鼠标事件*/
}
.clear-fix[data-v-93a07dda]:after {
  content: "";
  display: block;
  clear: both;
}
.top-square-box[data-v-93a07dda] {
  min-width: 702px;
  border: 1px solid #e5e6eb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  white-space: nowrap;
}
ul[data-v-93a07dda],
li[data-v-93a07dda] {
  margin: 0;
  padding: 0;
  list-style: none;
}
.left-square-y[data-v-93a07dda] {
  width: 4%;
  min-width: 28px;
  background-color: #f2f3f5;
}
.left-square-y li[data-v-93a07dda] {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  border-right: 1px solid #e5e6eb;
  border-bottom: 1px solid #e5e6eb;
  text-align: center;
}
.left-square-y li[data-v-93a07dda]:last-child {
  border-bottom: none;
}
.right-wrap[data-v-93a07dda] {
  width: 96%;
  overflow: hidden;
  white-space: nowrap;
}
.top-square-x[data-v-93a07dda] {
  white-space: nowrap;
  background-color: #f2f3f5;
}
.top-square-x ul[data-v-93a07dda] {
  display: block;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #e5e6eb;
  border-bottom: none;
}
.top-square-x li[data-v-93a07dda] {
  width: 4.066%;
  min-width: 28px;
  height: 40px;
  float: left;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #e5e6eb;
  border-bottom: 1px solid #e5e6eb;
}
.top-square-x li[data-v-93a07dda]:last-child {
  border-right: none;
}
.bottom-square-x[data-v-93a07dda] {
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.bottom-square-x .row + .row[data-v-93a07dda] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.bottom-square-x .row-list li[data-v-93a07dda] {
  float: left;
  height: 40px;
  width: 4.066%;
  min-width: 28px;
  background: #ffffff;
  border-bottom: 1px solid #e5e6eb;
  cursor: pointer;
  line-height: 40px;
}
.bottom-square-x .row-list li[data-v-93a07dda]:last-child {
  border-right: none;
  width: 4.166%;
}
.bottom-square-x .row-list li.select[data-v-93a07dda] {
  background: #bedaff;
}
.bottom-square-x .row:last-child li[data-v-93a07dda] {
  border-bottom: none;
}
li.right-border[data-v-93a07dda] {
  border-right: 1px solid #e5e6eb;
}