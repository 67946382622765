.select-btn[data-v-aea9c610] {
  margin-bottom: 13px;
  border: 1px solid #fd5454;
  color: #fd5454;
}
.text-button[data-v-aea9c610] {
  color: #fd5454 !important;
  font-size: 14px;
}
.el-scrollbar__wrap[data-v-aea9c610] {
  overflow: hidden !important;
  margin-right: 0 !important;
}
[data-v-aea9c610] .el-table--border {
  border-bottom: 1px solid #e4e4e4 !important;
}