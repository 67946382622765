.dataFilling[data-v-3c4348f4] {
  height: 100%;
  background-color: #fff;
  padding: 10px;
}
.dataFilling .table-wrap-style[data-v-3c4348f4] {
  height: calc(100% - 50px);
}
.dataFilling .c-tabs[data-v-3c4348f4] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.dataFilling .c-tabs .button-group[data-v-3c4348f4] {
  margin-right: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dataFilling .c-tabs .select-label-style[data-v-3c4348f4] {
  white-space: nowrap;
}
.dataFilling .Report-box .Report[data-v-3c4348f4] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  width: calc(12.5% - 15px);
  height: 50px;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 4px;
}
.dataFilling .Report-box .ReportActive[data-v-3c4348f4] {
  background-image: url(../../static/img/filling.898025f3.png);
  color: #fd5454;
}
.dataFilling .text[data-v-3c4348f4] {
  white-space: nowrap;
  padding-right: 10px;
}
.dataFilling .Reported-box[data-v-3c4348f4] {
  margin: 10px 0 20px 0;
  width: 100%;
  font-size: 14px;
}
.dataFilling .notReported[data-v-3c4348f4],
.dataFilling .Reported[data-v-3c4348f4] {
  padding-right: 10px;
}
.dataFilling .el-button--text[data-v-3c4348f4] {
  color: #3358e3;
}
.select-label-style[data-v-3c4348f4] {
  margin-right: 10px;
  font-size: 14px;
  color: #202020;
}
[data-v-3c4348f4] .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #202020 !important;
}
[data-v-3c4348f4] .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #fd5454 !important;
}
.over-hidden[data-v-3c4348f4] {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}