.inputWidth[data-v-c6a5929a] {
  width: calc(100% - 34px);
}
.common_tree_handle[data-v-c6a5929a] {
  width: 280px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #ffffff;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.popv_p[data-v-c6a5929a] {
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.searchInput[data-v-c6a5929a] {
  font-size: 12px;
}
.searchInput[data-v-c6a5929a] input {
  height: 28px;
}
.moreBtn[data-v-c6a5929a] {
  position: absolute;
  top: 14px;
  right: 9px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  padding: 7px;
}