
/*.dark .el-menu--horizontal {*/
/*  background-color: #F2F3F5 !important;*/
/*}*/
.dark .el-menu--horizontal .el-menu {
  background-color: #F2F3F5;
}
.el-menu--horizontal .el-menu--popup {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.el-popover .el-button--text {
  color: #333333 !important;
}
