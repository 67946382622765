.common_tree_handle[data-v-53794ddc] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-right: 1px solid #cccccc;
}
.popv_p[data-v-53794ddc] {
  line-height: 32px;
  text-align: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}