@charset "UTF-8";
@-webkit-keyframes scroll-4781747a {
from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
to {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    /* 根据列表项宽度调整 */
}
}
@keyframes scroll-4781747a {
from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
to {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    /* 根据列表项宽度调整 */
}
}
[data-v-4781747a] .weather-card .el-card__body {
  padding: 16px 12px 10px 12px !important;
}
iframe[data-v-4781747a] {
  width: 100%;
  height: 100%;
  border: none;
  /* 可选，移除边框 */
}
iframe html[data-v-4781747a],
iframe body[data-v-4781747a] {
  width: 100%;
  height: 100%;
}
[data-v-4781747a] .el-table {
  color: #000;
  font-size: 14px;
}
[data-v-4781747a] .el-progress__text {
  color: #f14045;
}
[data-v-4781747a].el-card {
  border-radius: 10px;
}
.company-index[data-v-4781747a] {
  height: 100%;
  width: 100%;
}
.project-list-box[data-v-4781747a],
.cockpit[data-v-4781747a] {
  width: calc(40% - 80px);
  margin-right: 20px;
  height: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.project-list-box .title .text[data-v-4781747a] {
  cursor: point;
}
.todo-list[data-v-4781747a],
.project-list[data-v-4781747a] {
  margin-top: 10px;
  width: 100%;
}
.echarts[data-v-4781747a] {
  margin-right: 20px;
}
.echarts[data-v-4781747a] {
  width: calc(30% + 80px);
}
.todo-list-box[data-v-4781747a],
.pre-class-list[data-v-4781747a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 30%;
}
.table-wrapper[data-v-4781747a] {
  height: calc(35vh - 52px);
}
.block-box[data-v-4781747a] {
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
}
.block-box .content[data-v-4781747a] {
  margin-top: 10px;
  height: calc(36vh - 42px);
}
.block-box .title[data-v-4781747a] {
  padding-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.block-box .title .icon[data-v-4781747a] {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 8px;
  border-radius: 5px;
  height: 20px;
  background: -webkit-gradient(linear, left bottom, left top, from(#f14045), to(#ffffff));
  background: linear-gradient(0deg, #f14045, #ffffff);
}
.block-box .title .text[data-v-4781747a] {
  margin-left: 40px;
}
.block-box .title .more[data-v-4781747a] {
  position: absolute;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  top: 12px;
  right: 10px;
}
.home-top[data-v-4781747a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.home-top .weather-control[data-v-4781747a] {
  width: 30%;
}
.home-top .home-statistics[data-v-4781747a] {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.home-top .home-statistics .card-item[data-v-4781747a] {
  cursor: pointer;
  width: calc(25% - 10px);
  margin-right: 20px;
  height: 120px;
  position: relative;
}
.home-top .home-statistics .card-item .content[data-v-4781747a] {
  position: absolute;
  top: 30px;
  right: 40px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.home-top .home-statistics .card-item .content .title[data-v-4781747a] {
  font-size: 16px;
}
.home-top .home-statistics .card-item .content .num[data-v-4781747a] {
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
}
.home-top .home-statistics .card-item .content .num-1[data-v-4781747a] {
  color: #4c95e7;
}
.home-top .home-statistics .card-item .content .num-2[data-v-4781747a] {
  color: #6ed8c6;
}
.home-top .home-statistics .card-item .content .num-3[data-v-4781747a] {
  color: #dd8b46;
}
.home-top .home-statistics .card-item .content .num-4[data-v-4781747a] {
  color: #e66c6c;
}
.home-top .home-statistics .card-item .content .num-1[data-v-4781747a]:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #4c95e7;
          text-decoration-color: #4c95e7;
}
.home-top .home-statistics .card-item .content .num-2[data-v-4781747a]:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #6ed8c6;
          text-decoration-color: #6ed8c6;
}
.home-top .home-statistics .card-item .content .num-3[data-v-4781747a]:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #dd8b46;
          text-decoration-color: #dd8b46;
}
.home-top .home-statistics .card-item .content .num-4[data-v-4781747a]:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: #e66c6c;
          text-decoration-color: #e66c6c;
}
.home-top .home-statistics .card-item .card-item-bg[data-v-4781747a] {
  width: calc(100% - 20px);
  position: absolute;
  bottom: 0;
  z-index: 1;
  top: 10px;
}
.weather-card[data-v-4781747a] {
  height: 120px;
}
.home-line-content[data-v-4781747a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
  height: calc(50% - 80px);
}
.class-list[data-v-4781747a] {
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  height: calc(100% - 52px);
  /* 对于Firefox */
}

/* 针对WebKit浏览器，如Chrome和Safari */
.class-list[data-v-4781747a]::-webkit-scrollbar {
  display: none;
  /* 隐藏滚动条 */
}
.class-list .list-item[data-v-4781747a] {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 40px);
}
.class-list .list-item .list-content[data-v-4781747a] {
  margin-left: 20px;
  width: calc(100% - 210px);
}
.class-list .list-item .list-content .meetingTitle[data-v-4781747a] {
  font-weight: bold;
}
.class-list .list-item .list-content .meetingContents[data-v-4781747a] {
  margin-top: 10px;
  font-size: 14px;
  display: -webkit-box;
  /* 启用弹性盒子布局模型 */
  -webkit-box-orient: vertical;
  /* 子元素垂直排列 */
  -webkit-line-clamp: 3;
  /* 限制在3行内 */
  overflow: hidden;
  /* 隐藏超出盒子的内容 */
  text-overflow: ellipsis;
  /* 超出部分显示省略号 */
  max-height: 70px;
  /* 设置最大高度 */
  line-height: 20px;
  /* 行高 */
  width: 100%;
  /* 设置宽度 */
  /* 设定最大高度 */
  /* 当内容超出元素时显示滚动条 */
}
.link-list[data-v-4781747a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: normal;
      -ms-flex-pack: normal;
          justify-content: normal;
  overflow: auto;
  height: calc(100% - 52px);
}
.link-list .link-item[data-v-4781747a] {
  cursor: pointer;
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.link-list .link-item .link-item-noImg-icon[data-v-4781747a] {
  width: 54px;
  height: 54px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(255, 168, 28, 0.2);
}
.link-list .link-item .link-item-noImg-icon i[data-v-4781747a] {
  font-size: 30px;
  font-weight: bold;
  color: #FFA81C;
}
.link-list .link-item .link-item-text[data-v-4781747a] {
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}