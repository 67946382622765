.treeBox[data-v-5273fac8] {
  width: 300px;
  height: calc(100vh - 125px);
  background-color: #FFFFFF;
  overflow: auto;
}
.treeTitle[data-v-5273fac8] {
  width: calc(100% - 20px);
  margin: auto;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid rgb(204, 204, 204);
}
[data-v-5273fac8] .avue-tree {
  min-height: unset;
}
.rightFormBox[data-v-5273fac8] {
  width: calc(100% - 299px);
  border-left: 1px solid #CCC;
  background-color: #FFFFFF;
}