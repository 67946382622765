.redCloumn[data-v-b55da1b4] {
  color: red;
}
.process-title[data-v-b55da1b4] {
  font-size: 18px;
  font-weight: 700;
}
.formBox[data-v-b55da1b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 20px;
}
.formBox .formBox-left[data-v-b55da1b4] {
  width: 700px;
  height: 400px;
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.formBox .formBox-left[data-v-b55da1b4] .el-carousel {
  width: 100%;
  height: 100%;
}
.formBox .formBox-left[data-v-b55da1b4] .el-carousel__container {
  height: 100%;
}
.formBox .formBox-left img[data-v-b55da1b4] {
  width: 100%;
  height: 100%;
}
.formBox .formBox-left .noImg-box[data-v-b55da1b4] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.formBox .formBox-left .noImg-box img[data-v-b55da1b4] {
  width: 280px;
  height: 280px;
}
.formBox .formBox-left .noImg-box .noImg-text[data-v-b55da1b4] {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #a1a2a2;
  margin-top: 10px;
}
.formBox .formBox-right-noImg[data-v-b55da1b4] {
  width: 100%;
}
.formBox .formBox-right[data-v-b55da1b4] .el-form {
  width: 100%;
}
.formBox .formBox-right[data-v-b55da1b4] .el-textarea.is-disabled .el-textarea__inner {
  color: #606266 !important;
}
.formBox .formBox-right .red-form-item[data-v-b55da1b4] .el-input.is-disabled .el-input__inner {
  color: red !important;
}
.formBox .formBox-right[data-v-b55da1b4] .el-divider {
  background-color: #f2f2f2;
}
.formBox .formBox-right[data-v-b55da1b4] .el-divider--horizontal {
  margin: 20px 0 !important;
}
.formBox .formBox-right-yesImg[data-v-b55da1b4] {
  width: calc(100% - 700px);
}
.formBox .formTop[data-v-b55da1b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 18px;
}
.formBox .formTop .formTopic[data-v-b55da1b4] {
  font-weight: bold;
  font-size: 18px;
  white-space: normal;
  padding-left: 12px;
}
.formBox .formTop .processResul-bg-item[data-v-b55da1b4] {
  width: 60px;
  text-align: center;
  color: #fff;
  padding: 3px 6px;
  margin-left: 12px;
}
.formBox .formTop .processResult[data-v-b55da1b4] {
  background-color: #f59a23;
}
.formBox .formTop .normal[data-v-b55da1b4] {
  background-color: #46a6ff;
}
.formBox .text-item[data-v-b55da1b4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0;
}
.formBox .text-item .text-label[data-v-b55da1b4]::after {
  display: inline-block;
  content: ":";
}
.formBox .text-item .text-content[data-v-b55da1b4] {
  margin-left: 10px;
}