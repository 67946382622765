@charset "UTF-8";
.el-input-number.el-input-number--small[data-v-024245a4] {
  width: 100%;
}
[data-v-024245a4] .el-tabs__content {
  background-color: transparent;
}
.main-content[data-v-024245a4] {
  widows: 100%;
  height: 100%;
}
.el-main[data-v-024245a4] {
  height: 100%;
  overflow: hidden;
}
[data-v-024245a4].inOut-content {
  height: calc(100% - 50px);
}
[data-v-024245a4].inOut-content #gridLayout {
  height: calc(100% - 112px);
}
[data-v-024245a4].inOut-content #gridLayout .avue-crud {
  height: 100%;
}
[data-v-024245a4].inOut-content #gridLayout .avue-crud .el-card {
  height: calc(100% - 46px);
}
[data-v-024245a4].inOut-content #gridLayout .avue-crud .avue-crud__pagination {
  height: 46px !important;
}
[data-v-024245a4].inOut-content #gridLayout .avue-crud .el-card__body {
  height: 100%;
}
[data-v-024245a4].inOut-content #gridLayout .avue-crud .el-card__body .el-form {
  height: 100%;
}
[data-v-024245a4].inOut-content #gridLayout .avue-crud .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-024245a4] .inventory-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: calc(100% - 60px);
}
[data-v-024245a4] .inventory-content .left {
  background-color: #fff;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  width: 60%;
  margin-right: 10px;
  height: 100%;
}
[data-v-024245a4] .inventory-content .left .el-card__body {
  padding: 10px !important;
  width: 100%;
  height: 100%;
}
[data-v-024245a4] .inventory-content .left .list {
  padding: 10px 5px 10px 10px;
  height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-v-024245a4] .inventory-content .left .list .list-card {
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 10px;
  border: 1px solid transparent !important;
}
[data-v-024245a4] .inventory-content .left .list .list-card .name {
  text-align: center;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 使用省略符号表示被截断的文本 */
}
[data-v-024245a4] .inventory-content .left .list .list-card .el-divider {
  width: calc(100% + 20px);
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
[data-v-024245a4] .inventory-content .left .list .list-card .bottom {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
[data-v-024245a4] .inventory-content .left .up-card {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 294px;
}
[data-v-024245a4] .inventory-content .left .up-card .upload {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-024245a4] .inventory-content .right {
  width: 40%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
[data-v-024245a4] .inventory-content .right .detail {
  background-color: #fff;
  height: 55%;
  margin-bottom: 10px;
}
[data-v-024245a4] .inventory-content .right .detail .el-form {
  overflow-y: scroll;
  height: calc(100% - 76px);
  padding: 10px;
}
[data-v-024245a4] .inventory-content .right .list {
  background-color: #fff;
  height: calc(45% - 10px);
}
[data-v-024245a4] .inventory-content .right .list #gridLayout {
  height: calc(100% - 46px);
}
[data-v-024245a4] .inventory-content .right .list #gridLayout .avue-crud {
  height: 100%;
}
[data-v-024245a4] .inventory-content .right .list #gridLayout .avue-crud .el-card {
  height: calc(100% - 46px);
}
[data-v-024245a4] .inventory-content .right .list #gridLayout .avue-crud .avue-crud__pagination {
  height: 46px !important;
}
[data-v-024245a4] .inventory-content .right .list #gridLayout .avue-crud .el-card__body {
  height: 100%;
}
[data-v-024245a4] .inventory-content .right .list #gridLayout .avue-crud .el-card__body .el-form {
  height: 100%;
}
[data-v-024245a4] .inventory-content .right .list #gridLayout .avue-crud .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-024245a4].cwDialog #gridLayout {
  height: calc(100% - 60px);
}
[data-v-024245a4].cwDialog #gridLayout .avue-crud {
  height: 100%;
}
[data-v-024245a4].cwDialog #gridLayout .avue-crud .el-card {
  height: 100%;
}
[data-v-024245a4].cwDialog #gridLayout .avue-crud .el-card .el-card__body {
  height: 100%;
}
[data-v-024245a4].cwDialog #gridLayout .avue-crud .el-card .el-card__body .el-form {
  height: 100%;
}
[data-v-024245a4].cwDialog #gridLayout .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-024245a4].cwDialog #gridLayout .avue-crud .avue-crud__pagination {
  height: auto;
}
.inventory-content .left .list .list-card-selected[data-v-024245a4] {
  border: 1px solid #409eff !important;
}
.image-slot[data-v-024245a4] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.disUoloadSty[data-v-024245a4] .el-upload--picture-card {
  display: none;
}
.disUoloadSty[data-v-024245a4] .el-upload__tip {
  display: none;
}
.disUoloadSty[data-v-024245a4] .el-upload--text {
  display: none;
}