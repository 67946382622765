.header-box[data-v-afb46484] {
  margin-top: 5px;
  margin-bottom: 5px;
}
.header-box .box-card[data-v-afb46484] {
  background: rgb(25, 144, 255);
  height: 100px;
  font-size: 18px;
  color: #FFFFFF;
  width: calc(25% - 20px);
}
.header-box .box-card .margin-t-10[data-v-afb46484] {
  margin-top: 10px;
}
.eventBox[data-v-afb46484] {
  margin-left: 12px;
}
.eventBox .eventItem[data-v-afb46484] {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0 8px;
  height: 26px;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  color: #333;
  font-size: 12px;
}
.deptTitle[data-v-afb46484] {
  margin-left: 5px;
}
.treeHeader[data-v-afb46484] {
  margin: 14px 12px;
  width: calc(100% - 24px) !important;
}
.shuttleBackBox[data-v-afb46484] {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shuttleBackBox .table-box[data-v-afb46484] {
  background-color: #FFFFFF;
  width: calc(100% - 280px);
}
[data-v-afb46484] .tree-disabled {
  color: #606060;
}