.table-dialog[data-v-74f7c3b9] .el-dialog__body {
  height: 70vh;
  padding: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.table-dialog[data-v-74f7c3b9] .el-dialog__body .SNOWS-common-search-box {
  margin-bottom: 0;
}
.table-dialog[data-v-74f7c3b9] .el-dialog__body .SNOWS-common-search-box .SNOWS-common-search-box-right {
  padding: 10px 10px 0 0;
}