[data-v-2e76d3d0] .userSelectGridLayout .avue-crud .el-table {
  height: 50vh !important;
  max-height: 50vh !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-2e76d3d0] .userSelectTreeLayout .el-tree {
  height: 50vh !important;
  max-height: 50vh !important;
  overflow-y: scroll;
}
[data-v-2e76d3d0] .el-radio__label {
  display: none !important;
}