[data-v-7813128e] .el-container {
  width: 100%;
  height: 100%;
}
.safetyOrg-container[data-v-7813128e] {
  width: 100%;
  height: 100%;
}
.safetyOrg-container .setting-tab[data-v-7813128e] {
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f5f7fa;
}
.safetyOrg-container .setting-tab[data-v-7813128e] .el-tabs {
  max-width: calc(100% - 83px);
}
.safetyOrg-container .setting-tab .tab-warp[data-v-7813128e] {
  max-width: calc(100% - 20px);
  overflow-x: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.safetyOrg-container .setting-tab .tab-warp .tab-item[data-v-7813128e] {
  width: 70px;
  padding: 5px 0;
  border: 1px solid #e8e8e8;
  margin-right: 5px;
  font-size: 14px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
}
.safetyOrg-container .setting-tab .tab-warp .tab-item[data-v-7813128e] .el-tooltip {
  width: 100%;
}
.safetyOrg-container .setting-tab .tab-warp .tab-item .el-tooltip-text[data-v-7813128e] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.safetyOrg-container .setting-tab .tab-warp .tab-item-active[data-v-7813128e] {
  background-color: #fff;
  color: #1990ff;
}
.safetyOrg-container .setting-tab .tab-button[data-v-7813128e] {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}
.safetyOrg-container .setting-tab[data-v-7813128e] .el-tabs__content {
  padding: 0;
}
.safetyOrg-container .safetyOrg-container-setting[data-v-7813128e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(100% - 40px);
}
.safetyOrg-container .safetyOrg-container-setting .container-setting[data-v-7813128e] {
  background-color: #fff;
  width: 54%;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-tool[data-v-7813128e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 4px 6px;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-tool .setting-tool-title[data-v-7813128e] {
  font-size: 16px;
  font-weight: 500;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp[data-v-7813128e] {
  height: calc(100% - 40.4px);
  position: relative;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp[data-v-7813128e] .zm-tree-org {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp[data-v-7813128e] .zoom-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp .org-warp[data-v-7813128e] {
  background-color: #108fee;
  color: #fff;
  font-size: 14px;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp .org-warp .org-name[data-v-7813128e] {
  padding: 5px 10px;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp .org-warp .person-list[data-v-7813128e] {
  border-top: 1px solid #ccc;
  padding: 5px 10px;
}
.safetyOrg-container .safetyOrg-container-setting .container-setting .setting-warp .org-warp .tool-list[data-v-7813128e] {
  border-top: 1px solid #ccc;
  padding: 5px 10px;
  text-align: right;
  font-size: 16px;
}
.safetyOrg-container .safetyOrg-container-setting .container-table[data-v-7813128e] {
  margin-left: 10px;
  width: 45%;
}
.safetyOrg-container .safetyOrg-container-setting .container-table .mb-10[data-v-7813128e] {
  margin-bottom: 10px;
}
.safetyOrg-container .safetyOrg-container-setting .container-table .table-box[data-v-7813128e] {
  overflow-y: auto;
  height: calc(50% - 5px);
  background-color: white;
}