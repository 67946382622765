
[data-v-17074295] .el-button+.el-button {
  margin-left: 0px;
}
.headers[data-v-17074295] {
  background: #ffffff;
  padding: 14px 12px;
}
.headers[data-v-17074295] .el-button {
  height: 28px;
}
