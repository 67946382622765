[data-v-676d6de3] .overseasPerson .avue-crud .el-table {
  height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}
.rejectTop[data-v-676d6de3] {
  width: calc(100% - 2px);
  background-color: #F7F7F7;
  height: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #DCDFE6;
  border-bottom: none;
  position: relative;
}
[data-v-676d6de3] .el-textarea .el-textarea__inner {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.selectModel[data-v-676d6de3] {
  width: 100%;
  height: 36px;
  line-height: 36px;
  color: #333333;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 10px;
}
.selectModel[data-v-676d6de3]:hover {
  background-color: #f5f7fa;
}
.selectActive[data-v-676d6de3] {
  color: #2979FF;
}
.line[data-v-676d6de3] {
  border-top: 1px dashed #303133;
  width: 100%;
  margin: 6px 0;
}
.approvalBtn[data-v-676d6de3] {
  width: 200px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.addBtn[data-v-676d6de3] {
  width: 100%;
}
[data-v-676d6de3] .addBtn i {
  font-size: 22px;
}